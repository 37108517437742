import { publish, subscribe, subscribeTemporarily } from '@trullock/pubsub'
import { getAuth } from "firebase/auth";
import UserLite from '../../functions/domain/users/userLite.js';
import { listenToProjection } from './lib/client-read-model.js';

let currentUser = null;

export function init() {

	subscribe('user changed', user => {
		currentUser = user
	})

	return new Promise(resolve => {
		let unsub = null;

		getAuth().onAuthStateChanged(async user => {
			if(user)
			{
				if(user.uid != currentUser?.id)
				{
					unsub && unsub();
					unsub = await listenToProjection([UserLite, user.uid], user => {
						publish('user changed', user)
						resolve();
					});
				}
			}
			else
			{
				publish('user changed', null)
				resolve();
			}
		});
	});
}

export function getCurrentUserLite() {
	return currentUser;
};

export function getCurrentUserId() {
	return currentUser?.id;
};


export function userChanged(filter)
{
	return new Promise(resolve => {
		subscribeTemporarily('user changed', u => {
			if(!filter || filter(u))
			{
				resolve(u);
				return true;
			}

			return false;
		})
	})
}

export function getAuthProvider(id){
	switch(id){
		case 'google.com':
			return new firebase.auth.GoogleAuthProvider()
		case 'github.com':
			return new firebase.auth.GithubAuthProvider()
		default:
			throw new Error('Unexpected auth provider: `' + id + '`')
	}
}
