<script>

export const requireAuth = false;
export const pageName = 'account-password-strength';
export const pageRoute = '/account/password-strength';
export const title = 'Password Strength'

export default {
	data() {
		return {
		 }
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Password strength</h1>
			<p class="text-muted text-center mb-5">Protect your accounts properly.</p>
			
			<p>Password reuse is normal. It's extremely risky, but it's so common because it's easy and people aren't aware of the potential impact. Attacks such as credential stuffing take advantage of reused credentials by automating login attempts against systems using known emails and password pairs.</p>
			<p>UKRA requires you to use a unique password which hasn't previously been breached (even if the password was used by someone else). We enforce this by securely checking the password you choose against a known list of leaked passwords via the <a href="https://haveibeenpwned.com/" target="_blank">hibp</a> service.</p>
			<p>If you can't set the password you want because you receive the error: <span class="text-danger">This is a common password, choose something more unique</span> this is because the password you chose has been found in past data breaches.</p>
			<p>We recommend using a randomly generated password, stored in a secure password manager so that even you don't have to really know or remember it.</p>
		</div>
	</div>
</div>
</template>