<script>
export const pageName = 'admin';
export const pageRoute = '/admin';
export const title = 'Admin'
export const requireAdmin = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { getUserViewByEmail, getUserViewByNumber } from '../../js/readModel.js';
import { navigate } from '@trullock/page-manager'
import { listenForProjections } from '../../js/lib/client-read-model.js';
import PendingCertificationAttempt from '../../../functions/domain/users/pendingCertificationAttempt.js';
import PendingMAPAttempt from '../../../functions/domain/users/pendingMAPAttempt.js';
import { showToast } from '../../js/utils/toast.js'

export default {
	data() {
		return { 
			//currentUser: null,
			searchTerm: "",
			pendingAttempts: [],
			pendingMAPAttempts: [],
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			await listenForProjections([PendingCertificationAttempt], this.pendingAttempts);
			await listenForProjections([PendingMAPAttempt], this.pendingMAPAttempts);
			this.ready = true;
		},
		search: async function() {
			showLoading();

			let user = null;

			let term = this.searchTerm.trim().toLowerCase();

			let matches = term.match(/^\#?(\d+)$/)
			
			if(matches)
				user = await getUserViewByNumber(parseInt(matches[1], 10));
			else
				user = await getUserViewByEmail(term);

			if(user)
			{
				navigate('/admin/user/' + user.id, { user })
			}
			else
			{
				showToast({
					message: 'Not found',
					style: 'bg-danger'
				})
			}
			hideLoading()
		},
		show: function(opts)
		{
			this.searchTerm = "";
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">

			<div class="col">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="search">
							<legend>Search criteria</legend>
							<div class="row">
								<div class="col">
									<div class="form-group mb-0">
										<input type="search" class="form-control" v-model="searchTerm" autocomplete="off" placeholder="e.g. member@example.com or #1234" required />
										<span class="invalid-feedback"></span>
									</div>
								</div>
								<div class="col-auto">
									<button type="submit" ref="btnSearch" class="btn btn-primary">Search <span class="fe fe-search"></span></button>
								</div>
							</div>
						</form>		
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<a href="/admin/email-members" class="btn btn-primary mr-2">Email members <span class="fe fe-send"></span></a>	
						<!-- <a href="/admin/import" class="btn btn-primary">Import <span class="fe fe-user-plus"></span></a>	 -->
					</div>
				</div>
				<div class="card">
					<table class="table card-table" v-if="pendingAttempts.length">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Name</th>
								<th scope="col">Level</th>
								<th scope="col">Flight</th>
								<th scope="col" class="shrink"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="attempt in pendingAttempts">
								<td>{{ attempt.submittedOn.format('yyy/MM/dd') }}</td>
								<td>{{ attempt.userName }}</td>
								<td>{{ attempt.level }}</td>
								<td>{{ attempt.flight }}</td>
								<td><a :href="`/admin/user/${attempt.userId}/attempt/${attempt.id}`" class="btn btn-primary">View attempt <span class="fe fe-arrow-right-circle"></span></a></td>
							</tr>
						</tbody>
					</table>
					<div class="card-body" v-else>
						<p class="mb-0">There are no pending certification approvals.</p>
					</div>
				</div>
				<div class="card">
					<table class="table card-table" v-if="pendingMAPAttempts.length">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Name</th>
								<th scope="col">Level</th>
								<th scope="col">Flight</th>
								<th scope="col" class="shrink"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="attempt in pendingMAPAttempts">
								<td>{{ attempt.submittedOn.format('yyy/MM/dd') }}</td>
								<td>{{ attempt.userName }}</td>
								<td>{{ attempt.level }}</td>
								<td>{{ attempt.flight }}</td>
								<td><a :href="`/admin/user/${attempt.userId}/map-attempt/${attempt.id}`" class="btn btn-primary">View attempt <span class="fe fe-arrow-right-circle"></span></a></td>
							</tr>
						</tbody>
					</table>
					<div class="card-body" v-else>
						<p class="mb-0">There are no pending MAP approvals.</p>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
