<script>

export const pageName = 'account-security';
export const pageRoute = '/account/security';
export const title = 'Set password';
export const requireEmailVerified = false;

import { GoogleAuthProvider, fetchSignInMethodsForEmail, getAuth, signInWithEmailAndPassword, signInWithPopup, signOut, unlink } from 'firebase/auth'
import { navigate } from '@trullock/page-manager';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { updatePassword } from 'firebase/auth';
import { getCurrentUserId } from '../../js/auth.js';
import UserLite from '../../../functions/domain/users/userLite.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { showToast } from '../../js/utils/toast.js';
import { validation } from '../../js/language.js';
import { authProviders } from '../../js/language.js';
import { countPasswordBreaches } from '../../js/lib/hibp.js';
import { showConfirm } from '../../js/utils/confirm.js';

export default {
	data() {
		return {
			email: null,
			hasExistingPass: false,
			providers: [],
			oldPassword: null,
			newPassword: null,
			ready: false
		 }
	},
	methods: {
		async boot(opts) {
			await listenToProjection([UserLite, getCurrentUserId()], user => {
				this.email = user.email;
			});

			this.ready = true;

		},
		changePassword: async function() {

			showLoading();			
			
			let breaches = await countPasswordBreaches(this.newPassword)
			if(breaches > 0)
			{
				this.$refs.newPassword.setCustomValidity(validation.messages.breached(breaches));
				this.$refs.newPassword.reportValidity();
				hideLoading();
				return;
			}

			if(this.hasExistingPass)
			{
				try
				{
					let signInResult = await signInWithEmailAndPassword(getAuth(), this.email, this.oldPassword)
					await updatePassword(signInResult.user, this.newPassword);
				}
				catch(error)
				{
					if(error.code == 'auth/wrong-password')
					{
						this.$refs.oldPassword.setCustomValidity(validation[error.code]);
						this.$refs.oldPassword.reportValidity();
					}
					else if(error.code == 'auth/weak-password')
					{
						this.$refs.newPassword.setCustomValidity(validation[error.code]);
						this.$refs.newPassword.reportValidity();
					}
					
					hideLoading();
					return;
				}
			}
			else
			{
				let currentUserId = getCurrentUserId();

				let signInResult = await signInWithPopup(getAuth(), new GoogleAuthProvider())
				
				if(signInResult.user.uid != currentUserId)
				{
					await signOut(getAuth())

					showConfirm({
						title: 'Error changing password',
						message: 'When confirming your identity you logged in as a different user. The password on either account has not been changed. We have signed you out to avoid confusion. You need to sign back in and start again.',
						dismissable: false,
						buttons: [
							{
								text: 'I understand',
								style: 'primary',
								action: async () => {
									
									// hard navigation to tear down JS envrionment
									window.location = '/';
								}
							}
						]
					})

					return;
				}

				// TODO: handle changing user, errors etc
				await updatePassword(signInResult.user, this.newPassword);
			}

			this.newPassword = null;

			showToast({
				message: 'Password changed',
				style: 'bg-success'
			})

			navigate('/')

			hideLoading();
		},
		unlinkProvider: async function(provider){
			showConfirm({
				title: 'Confirm unlinking',
				message: 'Are you sure you want to unlink this authentication provider?',
				buttons: [
					{
						text: 'Sign out',
						style: 'primary',
						action: async () => {
							
							await unlink(getAuth().currentUser, provider.id)
							
							// we really mean to update the UI here
							this.show();
							
							showToast({
								style: 'bg-success',
								message: authProviders[profile.providerId] + ' authentication removed.'
							})
						}
					}
				]
			})
			
		},
		show: async function(opts) {
			let methods = await fetchSignInMethodsForEmail(getAuth(), this.email)
			this.hasExistingPass = methods.indexOf('password') != -1;

			this.oldPassword = null;
			this.newPassword = null;

			let passwordAuth = getAuth().currentUser.providerData.find(p => p.providerId == "password");
			let externalProviders = getAuth().currentUser.providerData.filter(x => x.providerId != "password");

			this.providers = externalProviders.map(profile => ({
				id: profile.providerId,
				name: authProviders[profile.providerId],
				email: profile.email,
				avatar: profile.photoURL,
				unlinkable: passwordAuth || externalProviders.length > 1
			}));
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center py-5">
		<div class="col-12 col-md-6">
			<h1 class="display-4 text-center mb-3">Security</h1>
			<p class="text-muted text-center mb-5">Protect your account.</p>

			<p class="text-muted mb-4">You can sign in using external authentication providers like Google. Manage those connections here.</p>
			<div class="card">
				<div class="card-body">
					<div class="list-group list-group-flush my-n3">
						<div v-for="provider in providers" class="list-group-item">
							<div class="row align-items-center">
								<div class="col-auto">
									<div class="avatar avatar-sm">
										<img class="avatar-img rounded-circle" :src="provider.avatar">
									</div>
								</div>
								<div class="col">
									<h4 class="mb-1">{{ provider.name }}</h4>
									<small class="text-muted">{{ provider.email  }}</small>
								</div>
								<div class="col-auto">
									<button :disabled="!provider.unlinkable" class="btn btn-sm btn-danger" @click.prevent="unlinkProvider(provider)">Unlink</button>
								</div>
							</div>
						</div>
						<div v-if="providers.length == 0" class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<h4 class="mb-1 js-name">No providers configured</h4>
									<small class="text-muted">You have not associated any external auth providers.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<small class="text-muted">You can only unlink a provider if you have more than one or a password set.</small>

			<hr class="my-5">
			<p class="text-muted mb-4">Set or change your password.</p>
			
			<form ref="form" @submit.prevent="changePassword">
				<div class="row">
					<div v-if="hasExistingPass" class="col-12">
						<div class="form-group mb-4">
							<label class="form-label">Current password</label>
							<div class="input-group has-validation">
								<input type="password" class="form-control" ref="oldPassword" v-model="oldPassword" name="current-password" autocomplete="current-password" required minlength="8" />
								<div class="input-group-append">
									<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
								</div>
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group mb-4">
							<label class="form-label">New password</label>
							<div class="input-group has-validation">
								<input type="password" class="form-control" ref="newPassword" v-model="newPassword" name="new-password" autocomplete="new-password" placeholder="e.g. Something long and unique" required minlength="8" />
								<div class="input-group-append">
									<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
								</div>
								<span class="invalid-feedback"></span>
							</div>
						<span class="form-text small text-muted mt-2 mb-0">8 characters or more, must not be a <a href="/account/password-strength" target="_blank">common password <span class="fe fe-external-link"></span></a>.</span>
						</div>
					</div>
				</div>
				
				
				<button class="btn d-block btn-primary btn-block" type="submit">Update password <span class="fe fe-check-circle"></span></button>
			</form>
		</div>
	</div>
</div>
</template>