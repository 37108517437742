<script>
export const pageName = 'tools-ejection-charge';
export const pageRoute = '/tools/ejection-charge';
export const title = 'Ejection Charge'

export default {
	data() {
		return { 
			diameter: 98,
			length: 300,
			psi: 12,

			gBP: 0.90,
			m2: 5,
			m3: 2,
		}
	},

	methods: {
		calculate() {
			try
			{
				let volume = Math.PI * Math.pow(this.diameter / 2.0, 2) * this.length;
				
				let force = 6.89476 * this.psi * volume

				const Rc = 12.1579
				const T = 1739

				this.gBP = ((force / (Rc * T * 9.807)) / 1000).toFixed(2)

				let bulkheadForce = 6.89476 * this.psi * Math.PI * Math.pow(this.diameter / 2.0, 2) * 0.001
				this.m2 = Math.floor(bulkheadForce / 120) // 120 == 27lbs -> N
				this.m3 = Math.floor(bulkheadForce / 298) // 298 == 67lbs -> N
			}
			catch(e)
			{
				this.gBP = 0
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Ejection Charge Calculator</h1>
				<p class="text-muted text-center mb-5">Work how how much FFFFg Black Powder you need.</p>
				<p class="alert alert-light"><span class="fe fe-alert-triangle"></span> This calculator is approximate and should only be used a guide. Always perform multiple ground tests before comitting to a given mass of ejection charge.</p>
				<form>
					<fieldset>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Parachute bay internal diameter</label>
									<div class="input-group has-validation">
										<input @input="calculate" type="number" min="1" max="99999" step="1" autocomplete="off" v-model="diameter" class="form-control" placeholder="e.g. 98" />
										<div class="input-group-append">
											<div class="input-group-text">
												mm
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label>Parachute bay internal length</label>
									<div class="input-group has-validation">
										<input @input="calculate" type="number" min="1" max="99999" step="1" autocomplete="off" v-model="length" class="form-control" placeholder="e.g. 150" />
										<div class="input-group-append">
											<div class="input-group-text">
												mm
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Desired pressure</label>
									<div class="input-group has-validation">
										<input @input="calculate" type="number" min="1" max="1000" step="1" autocomplete="off" v-model="psi" class="form-control" placeholder="e.g. 10" />
										<div class="input-group-append">
											<div class="input-group-text">
												psi
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label>FFFFg Black Powder</label>
									<div class="input-group has-validation">
										<input type="number" readonly v-model="gBP" class="form-control" />
										<div class="input-group-append">
											<div class="input-group-text">
												g
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Max sheer pins</label>
									<div class="input-group has-validation">
										<input readonly v-model="m2" class="form-control" />
										<div class="input-group-append">
											<div class="input-group-text">
												M2
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label>Max sheer pins</label>
									<div class="input-group has-validation">
										<input type="number" readonly v-model="m3" class="form-control" />
										<div class="input-group-append">
											<div class="input-group-text">
												M3
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</div>
						
					</fieldset>
				</form>
			</div>
		</div>
    </div>
</template>
