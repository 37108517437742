<script>
export const pageName = 'tools-vent-holes';
export const pageRoute = '/tools/vent-holes';
export const title = 'Vent Holes'

export default {
	data() {
		return { 
			diameter: 98,
			length: 150,
			ventCount: 3,
			ventDiameter: 3
		}
	},

	methods: {
		calculate() {
			try
			{
				this.ventDiameter = (0.004396 * this.diameter * Math.sqrt(this.length / this.ventCount)).toFixed(1)
			}
			catch(e)
			{
				this.ventDiameter = 0
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Vent Hole Calculator</h1>
				<p class="text-muted text-center mb-5">Work how how many and how large your vent holes need to be.</p>
				<p>This calculator uses the rule of thumb of 1/4" vent hole for every 100 cubic inches of volume.</p>
				<p>The combined area of all vent holes will be equivalent to that of a single larger hole.</p>
				<p class="alert alert-light"><span class="fe fe-info"></span> A single vent hole of the appropriate size is susceptible to noise caused by breezes and wind gusts. These effects are minimized by using multiple vent holes distributed around the volume, with 3 holes being considered a practical minimum.</p>
				<form>
					<fieldset>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Electronics bay internal diameter</label>
									<div class="input-group has-validation">
										<input @input="calculate" type="number" min="1" max="99999" step="1" autocomplete="off" v-model="diameter" class="form-control" placeholder="e.g. 98" />
										<div class="input-group-append">
											<div class="input-group-text">
												mm
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label>Electronics bay internal length</label>
									<div class="input-group has-validation">
										<input @input="calculate" type="number" min="1" max="99999" step="1" autocomplete="off" v-model="length" class="form-control" placeholder="e.g. 150" />
										<div class="input-group-append">
											<div class="input-group-text">
												mm
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Number of vent holes</label>
									<input @input="calculate" type="number" min="1" max="99999" step="1" autocomplete="off" v-model="ventCount" class="form-control" placeholder="e.g. 3" />
									<span class="invalid-feedback"></span>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label>Vent hole diameter</label>
									<div class="input-group has-validation">
										<input type="number" min="1" max="99999" step="0.1" autocomplete="off" readonly v-model="ventDiameter" class="form-control" />
										<div class="input-group-append">
											<div class="input-group-text">
												mm
											</div>
										</div>
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
				<p class="small text-muted">Calculator inspired by <a href="https://wiki.freecad.org/Rocket_Vent_Hole_Size_Calculator" target="_blank">https://wiki.freecad.org/Rocket_Vent_Hole_Size_Calculator <span class="fe fe-external-link"></span></a></p>
			</div>
		</div>
    </div>
</template>
