<script>
export const pageName = 'help';
export const pageRoute = '/help';
export const title = 'Help'
export const requireEmailVerified = false;

export default {
	data() {
		return { 
		}
	},

	methods: {
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Help</h1>
				<p class="text-muted text-center mb-5">What are you stuck with?</p>

				<h2>Membership</h2>
				<p>For more details on UKRA membership, please see <a href="http://www.ukra.org.uk/membership" target="_blank">the membership pages</a> on our main website.</p>
				<h3>How do I order a membership card?</h3>
				<p>Membership cards are now digital, simply present the home page of this website at launch events to prove your current membership status and certification level.</p>
				<p>If you have an active membership you can <a href="/membership/buy-a-card">order a physical card here</a> or by using the link from the main page under Membership:</p>
				<div class="card indented my-5">
					<div class="card-header">
						<h4 class="card-header-title">Membership</h4>
					</div>
					<div class="card-body">
						<p class="mb-0 small alert alert-light">UKRA membership cards are now digital,<br />but you can still <a href="/membership/buy-a-card">order a physical card</a>.</p>
					</div>
				</div>
				<p class="alert alert-light"><span class="fe fe-info"></span> Please note that UKRA is kindly staffed by volunteers on a part time basis, so it may take a short time to process your request.</p>

				<p>For any further enquiries please email <a href="mailto:membership@ukra.org.uk">membership@ukra.org.uk</a>.</p>
				
				
				<hr class="my-5">
				

				<h2>Certification</h2>
				<p>For help understanding the certification process, please see <a href="http://www.ukra.org.uk/certification" target="_blank">the certification pages</a> on our main website.</p>

				<h3>How do I order a printed certificate?</h3>
				
				<p>On the main page, click the <span class="btn btn-sm btn-outline-success no-interact">Completed <span class="fe fe-check-circle"></span></span> button next to the certification level you wish to order:</p>
				<div class="card no-interact indented my-5">
					<div class="card-header">
						<h4 class="card-header-title">Certification</h4>
					</div>
					<div class="card-body">
						<div class="row mb-4">
							<div class="col">
								Level 1
							</div>
							<div class="col-auto">
								<button class="btn btn-sm btn-outline-success">Completed <span class="fe fe-check-circle"></span></button>
							</div>
						</div>
						<div class="row mb-4">
							<div class="col"> 
								Level 2
							</div>
							<div class="col-auto">
								<button class="btn btn-sm btn-outline-success">Completed <span class="fe fe-check-circle"></span></button>
							</div>
						</div>
						<div class="row">
							<div class="col">
								Level 3
							</div>
							<div class="col-auto">
								<button class="btn btn-sm btn-primary">Start <span class="fe fe-arrow-right-circle"></span></button>
							</div>
						</div>
					</div>
				</div>

				<p>From there you can click the <span class="btn btn-sm btn-primary no-interact">Order a certificate <span class="fe fe-award"></span></span> button:</p>
				
				<div class="card no-interact indented my-5">
					<div class="card-body">
						<p>Why not order a certificate to hang in your rocket workshop?</p>
						<button class="btn btn-primary btn-block">Order a certificate <span class="fe fe-award"></span></button>
					</div>
				</div>

				<p class="alert alert-light"><span class="fe fe-info"></span> Please note that UKRA is kindly staffed by volunteers on a part time basis, so it may take a short time to process your request.</p>

				<p>For any further enquiries please email <a href="mailto:membership@ukra.org.uk">membership@ukra.org.uk</a>.</p>
				
				<hr class="my-5">

				<h2>Technical issues</h2>
				<p>If you are experiencing any technical issues with the membership portal please email <a href="mailto:membership@ukra.org.uk">membership@ukra.org.uk</a> providing as much detail as possible.</p>
			</div>
		</div>
    </div>
</template>
