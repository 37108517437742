export const firebase = {
	apiKey: "AIzaSyDp5BAg5shjRJx8oQ5Xh5vxKdB7ZniGJ-Y",
	authDomain: "members.ukra.org.uk",
	projectId: "ukra-membership-30836",
	storageBucket: "ukra-membership-30836.appspot.com",
	messagingSenderId: "11354919630",
	appId: "1:11354919630:web:3cc37e00deda6299f2769f",
	measurementId: "G-BR3YDD3J30"
};

export const recaptchaKey = "6LfC7dspAAAAACLGaj0UYQAyW_gpSXIsoSBdku3U";

export const stripeKey = process.env.STRIPE_PK;