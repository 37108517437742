<script>
export const pageName = 'map-level-2';
export const pageRoute = '/model-achievement-programme/level-2';
export const title = 'MAP Level 2'
export const requireMembership = true;
export const requireCompleteAccount = true;

import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { navigate } from '@trullock/page-manager'
import { showToast } from '../../js/utils/toast.js'
import UserView from '../../../functions/domain/users/userView.js';
import DateTime from '../../../functions/lib/dateTime.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { renderMAPCert } from '../../js/utils/renderer.js'

export default {
	data() {
		return {
			currentUser: null,
			
			minDate: new DateTime(2024, 1, 1),
			maxDate: DateTime.today,

			flownOn: null,
			location: null,
			rocketName: null,
			rocketMotorDesignations: null,
			rocketMotorManufacturers: null,
			rocketType: '',
			rocketComments: null,

			flightCard: null,

			certImg: null,

			ready: false
		}
	},

	methods: {
		async boot(opts) {
			this.modal = new Modal(this.$refs.cropper);

			await listenToProjection([UserView, getCurrentUserId()], async user => {
				this.currentUser = user;
				this.certImg = await renderMAPCert(user, 'level2');
			});

			this.ready = true;
		},
		stageBadgeText(stage)
		{
			return stage.status == 'pending' ? 'Pending' : 
				stage.status == 'passed' ? 'Passed' : 
				'Not started'
		},
		stageBadgeClass(stage)
		{
			return stage.status == 'pending' ? 'badge-secondary' : 
				stage.status == 'passed' ? 'badge-success' : 
				'badge-light'
		},
		attemptBadgeText(stage)
		{
			return stage.result == null ? 'Pending' : 
				stage.result == 'passed' ? 'Passed' : 
				'Failed'
		},
		attemptBadgeClass(stage)
		{
			return stage.result == null ? 'badge-secondary' : 
				stage.result == 'passed' ? 'badge-success' : 
				'badge-danger'
		},
		show() {
			functions.warmUp.userSubmitMAPAttempt()
		},
		async submit() {

			// we have to manually validate the file upload because `required` doesnt have good UX cross browser
			if(!this.flightCard)
			{
				this.$refs.fupFlightCard.setCustomValidity('Please provide an picture of your completed flight card');
				this.$refs.fupFlightCard.reportValidity();
				return;
			}
			
			showLoading();

			let result = await functions.userSubmitMAPAttempt({ 
				type: this.currentUser.map.level2.nextStage,
				attempt: {
					flownOn: new DateTime(this.flownOn),
					location: this.location,
					name: this.rocketName,
					motorDesignations: this.rocketMotorDesignations,
					motorManufacturers: this.rocketMotorManufacturers,
					type: this.rocketType,
					comments: this.rocketComments,
					flightCard: this.flightCard
				}
			});
			
			// reset form
			this.flightCard = null;
			this.flownOn = null;
			this.location = null;
			this.rocketName = null;
			this.rocketMotorDesignations = null;
			this.rocketMotorManufacturers = null;
			this.rocketType = '';
			this.rocketComments = null;

			if(!result.success)
			{
				showToast({ message: 'Error submitting attempt', style: 'bg-danger'})
				hideLoading();
				return;
			}

			navigate('/');

			showToast({
				message: 'MAP attempt submitted',
				style: 'bg-success'
			})

			hideLoading();
		},
		crop() {
			showLoading();

			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
				
				let canvas = this.cropper.getCroppedCanvas({ });

				this.flightCard = canvas.toDataURL();

				canvas.toBlob(blob => {
					this.$refs.fupFlightCard.blobData = {
						blob: blob,
						name: 'flight-card.jpg'
					};
					this.$refs.lblFlightCard.innerText = this.$refs.fupFlightCard.pendingName;
				});

				hideLoading();

			}, 1)
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {
			this.$refs.fupFlightCard.setCustomValidity('');
			this.$refs.fupFlightCard.reportValidity();

			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupFlightCard.blobData = null;

				let $img = new Image();
				
				$img.addEventListener('load', e => {
					this.$refs.fupFlightCard.innerHTML = "Choose image&hellip;";
					this.$refs.fupFlightCard.pendingName = name;
					this.$refs.imgCropper.src = url;

					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'

					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						viewMode: 1,
						autoCropArea: 1
					});
				})
				$img.addEventListener('error', e => {
					this.$refs.fupFlightCard.setCustomValidity('File not a recognised image');
					this.$refs.fupFlightCard.reportValidity();
				})
				$img.src = url;
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">MAP Level 2</h4>
					</div>
					<div class="card-body">
						<p>For Level 2, two tasks must be completed, again a mandatory task, and additionally, one of a number of optional tasks. It is recommend that kits are used for level 2 tasks.</p>
						<p>Detailed information about the <a href="https://www.ukra.org.uk/certification/model-achievement-programme/map-level-2/" target="_blank">MAP Level 2 Process <span class="fe fe-external-link"></span></a> can be found on the main UKRA website.</p>
						<p>Download the <a href="https://www.ukra.org.uk/wp-content/uploads/2024/07/ukra-map-flight-card.pdf" target="_blank">Flight Card <span class="fe fe-external-link"></span></a> for use in the field.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-12 col-lg-8">
				<div class="card" v-if="currentUser.map.level2.status == 'passed'">
					<div class="card-header">
						<h4 class="card-header-title">Complete</h4>
					</div>
					<div class="card-body">
						<p>Congratulations! You've passed your MAP Level 2.</p>
						<p>Level 3 is the next challenge, <a href="/model-achievement-programme/level-3">let's get started</a>! Alternatively, scroll down to submit additional Level 2 tasks to earn more badges.</p>
						<p>Download your certificate below to print at home.</p>
						<a :download="`map-level-2-cert.png`" :href="certImg" target="_blank"><img :src="certImg" style="max-width: 100%" /></a>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">{{
							currentUser.map.level2.stage1.status != 'passed' ? 'Task 1' :
							currentUser.map.level2.stage2.status != 'passed' ? 'Task 2' :
							'Extra task'
							}}</h4>
					</div>
					<div class="card-body">
						<template v-if="currentUser.map.level2.status == 'passed'">
							<p>Although you've completed Level 2, you can still submit more tasks to earn more Level 2 badges.</p>
							<hr class="my-5">
						</template>
						<template v-if="currentUser.map.level2.isAwaitingApproval">
							<p class="mb-0">Please wait whilst UKRA validates your attempt. You should hear back soon.</p>
						</template>
						<template v-else>
							<form ref="form" @submit.prevent="submit">
								<fieldset>
									<legend>Attempt details</legend>
									<div class="form-group">
										<label>Date</label>
										<input type="date" class="form-control" required v-model="flownOn" autocomplete="off" :max="`${maxDate.format('yyyy-MM-dd')}`" :min="`${minDate.format('yyyy-MM-dd')}`"/>
										<span class="invalid-feedback"></span>
									</div>
									<div class="form-group">
										<label>Location</label>
										<input type="text" class="form-control" v-model="location" autocomplete="off" required />
										<span class="invalid-feedback"></span>
									</div>
									<hr>
									<div class="form-group">
										<label>Rocket Name</label>
										<input type="text" class="form-control" v-model="rocketName" autocomplete="off" required />
										<span class="invalid-feedback"></span>
									</div>
									<div class="row">
										<div class="col">
											<div class="form-group">
												<label>Motor Manufacturer(s)</label>
												<input type="text" v-model="rocketMotorManufacturers" autocomplete="off" class="form-control" placeholder="e.g. Cesaroni" required />
												<span class="invalid-feedback"></span>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label>Motor Designation(s)</label>
												<input type="text" v-model="rocketMotorDesignations" autocomplete="off" class="form-control" placeholder="e.g. G80SK" required />
												<span class="invalid-feedback"></span>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<div class="form-group">
												<label>Build Type</label>
												<select v-model="rocketType" class="form-control" required>
													<option value="" disabled selected>Choose...</option>
													<option value="Kit">Kit</option>
													<option value="Modified Kit">Modified Kit</option>
													<option value="Scratch Build">Scratch Built</option>
													<option value="Other">Other/Unknown</option>
												</select>
												<span class="invalid-feedback"></span>
											</div>
										</div>
										<div class="col">
										</div>
									</div>
									<div class="form-group">
										<label for="">Comments</label>
										<textarea v-model="rocketComments" placeholder="" autocomplete="off" rows="5" class="form-control"></textarea>
										<span class="invalid-feedback"></span>
									</div>
								</fieldset>
								
								<hr>
								<fieldset>
									<div class="field form-group">
										<label>
											Upload a picture of the signed flight card
											<div class="dz-default dz-message py-3" v-if="flightCard">
												<div class="avatar avatar-xxl">
													<img class="avatar-img" :src="flightCard" alt="Flight Card">
												</div>
											</div>
										</label>
										<div class="custom-file">
											<input type="file" @change="inputChanged" ref="fupFlightCard" class="custom-file-input" name="flight-card" accept="image/*;capture=camera">
											<label ref="lblFlightCard" class="custom-file-label text-muted">Choose image&hellip;</label>
											<span class="invalid-feedback"></span>
										</div>
										<span class="form-text text-muted small mb-0">Must be a jpeg or png</span>
									</div>
								</fieldset>
								<button type="submit" class="btn btn-lg btn-primary btn-block">Submit attempt <span class="fe fe-check-circle"></span></button>
							</form>
						</template>
					</div>
				</div>
			</div>
			<div class="col col-12 col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Progress</h4>
					</div>
					<div class="card-body">
						<div class="text-secondary small mb-4">Complete both tasks for Level 2.</div>
						<div v-if="currentUser.map.level2.grantedOn" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> You were granted MAP Level 2 outside of the normal process, so your progress may show as incomplete.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Task 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.map.level2.stage1)}`">
											{{ stageBadgeText(currentUser.map.level2.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Task 2
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.map.level2.stage2)}`">
											{{ stageBadgeText(currentUser.map.level2.stage2) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">History</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3" v-if="currentUser.map.level2.stage1.attempts.length || currentUser.map.level2.stage2.attempts.length">
							<div class="list-group-item" v-for="attempt in currentUser.map.level2.stage1.attempts.sort((a,b) => a.submittedOn - b.submittedOn)">
								<div class="row">
									<div class="col small">
										<h5>Task 1</h5>
										<span class="small text-muted">{{ attempt.submittedOn.format('yyyy/MM/dd HH:mm') }}</span>
									</div>
									<div class="col-auto">
										<div :class="`badge ${attemptBadgeClass(attempt)}`">
											{{ attemptBadgeText(attempt) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item" v-for="attempt in currentUser.map.level2.stage2.attempts.sort((a,b) => a.submittedOn - b.submittedOn)">
								<div class="row">
									<div class="col small">
										<h5>Task 2</h5>
										<span class="small text-muted">{{ attempt.submittedOn.format('yyyy/MM/dd HH:mm') }}</span>
									</div>
									<div class="col-auto">
										<div :class="`badge ${attemptBadgeClass(attempt)}`">
											{{ attemptBadgeText(attempt) }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<p class="small text-muted mb-0" v-else>You have not previously submitted any MAP Level 2 attempts.</p>
					</div>
				</div>
			</div>
		</div>
    </div>
	
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
					<p>Your picture must be good quality and clearly legible.</p>
                    <div class="img-container">
                        <img ref="imgCropper" :src="flightCard" class="">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
