<script>
export const pageName = 'membership-buy-card';
export const pageRoute = '/membership/buy-a-card';
export const title = 'Buy a membership card'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { loadStripe } from '../../js/utils/stripe.js'
import UserView from '../../../functions/domain/users/userView.js';

export default {
	data() {
		return {
			currentUser: null,
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], user => this.currentUser = user);
			this.stripe = await loadStripe();
			this.ready = true;
		},

		async show(opts) {
			
			let sessionResponse = await functions.stripeCreateSessionCard();
			this.checkout = await this.stripe.initEmbeddedCheckout({
				fetchClientSecret: () => sessionResponse.client_secret
			});

			this.checkout.mount(this.$refs.stripeMount);
		},

		hide()
		{
			this.checkout?.destroy();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Buy a membership card</h4>
					</div>
					<div class="card-body">
						<div ref="stripeMount"></div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
