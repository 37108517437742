import Page from '../../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { getAuth, signOut } from 'firebase/auth'

export default pageManager.registerPage('account-sign-out', '/account/sign-out', class extends Page {
	// this is false in case you get in a mess
	static requireAuth = false;	
	static existingDomSelector = null;
	
	constructor()
	{
		super(null);
	}

	get title()
	{
		return 'Sign out';
	}

	async show()
	{
		await signOut(getAuth());
		// hard navigation to tear down JS envrionment
		window.location = '/';
	}

	hide()
	{
		return Promise.resolve();
	}
})