import { getMembershipImportDate } from '../../src/site.config.js'
import DateTime from "../../lib/dateTime.js"

export default class UserView {
	id = null
	createdOn = null
	number = null
	name = null
	BMFANumber = null
	BMFASurname = null
	email = null
	avatar = null
	hasSetAvatar = false
	isAdmin = false
	bornOn = null
	usingGoogleWallet = false

	certification = new UserView.Certification()
	rso = new UserView.RSO();
	map = new UserView.MAP()
	membership = new UserView.Membership()

	get isComplete()
	{
		// Keep in sync with userLite
		return this.BMFANumber != null 
				&& this.bornOn != null
				&& this.hasSetAvatar;
	}
	
	get wasImported()
	{
		return this.createdOn < getMembershipImportDate();
	}

	get age()
	{
		if(!this.bornOn)
			return null;

		var today = DateTime.today;
		var age = today.year - this.bornOn.year;
		var m = today.month - this.bornOn.month;
		if (m < 0 || (m === 0 && today.day < this.bornOn.day))
			age--;
		
		return age;
	
	}

	static Certification = class {
		level1 = new UserView.Certification.Level1()
		level2 = new UserView.Certification.Level2()
		level3 = new UserView.Certification.Level3()

		get nextStage()
		{
			return this.level1.nextStage() 
				|| this.level2.nextStage() 
				|| this.level3.nextStage()
		}

		get level()
		{
			if(this.level3.status == 'passed')
				return 'level3';

			if(this.level2.status == 'passed')
				return 'level2';
			
			if(this.level1.status == 'passed')
				return 'level1';
			
			return null;
		}

		getAttempt(id) {
			let attempt = this.level1.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level1.stage2.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level1.stage3.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level2.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level2.stage2.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level3.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			return null;
		}

		static Attempt = class {
			id = null
			submittedOn = null

			date = null
			location = null
			name = null
			cg = null
			cp = null
			diameter = null
			length = null
			motorDesignations = null
			motorManufacturers = null
			sm = null
			predictedAlt = null
			type = null
			comments = null
			
			processedOn = null
			processedByUserId = null
			result = null
			reason = null
		}

		static Stage = class {
			attempts = []

			get status()
			{
				if(this.attempts.length == 0)
					return 'not-started';
				
				if(this.attempts[this.attempts.length - 1].result == null)
					return 'pending'

				if(this.attempts[this.attempts.length - 1].result == 'passed')
					return 'passed'
				
				return 'failed';
			}

			get passedOn()
			{
				if(!this.attempts.length)
					return null;

				if(this.attempts[this.attempts.length - 1].result == 'passed')
					return this.attempts[this.attempts.length - 1].submittedOn;

				return null;
			}
		}

		static Level1 = class {
			grantedOn = null;

			stage1 = new UserView.Certification.Stage()
			stage2 = new UserView.Certification.Stage()
			stage3 = new UserView.Certification.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending' 
					|| this.stage2.status == 'pending' 
					|| this.stage3.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';

				if(this.stage1.status == 'not-started')
					return 'not-started';

				if(this.stage3.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'L1S1';

				if(this.stage2.status != 'passed')
					return 'L1S2';

				if(this.stage3.status != 'passed')
					return 'L1S3';

				return null;
			}

			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage3.passedOn
				}
			}
		}

		static Level2 = class {
			grantedOn = null
			stage1 = new UserView.Certification.Stage()
			stage2 = new UserView.Certification.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending' 
					|| this.stage2.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';
				
				if(this.stage1.status == 'not-started')
					return 'not-started';

				if(this.stage2.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'L2S1';

				if(this.stage2.status != 'passed')
					return 'L2S2';;

				return null;
			}
			
			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage2.passedOn
				}
			}
		}

		static Level3 = class {
			grantedOn = null
			stage1 = new UserView.Certification.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';

				if(this.stage1.status == 'not-started')
					return 'not-started';

				if(this.stage1.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'L3S1';

				return null;
			}

			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage1.passedOn;
				}
			}
		}
	}

	static RSO = class {
		mode = null;
		model = new UserView.RSO.Level()
		level1 = new UserView.RSO.Level()
		level2 = new UserView.RSO.Level()
		level3 = new UserView.RSO.Level()

		get level()
		{
			if(this.level3.status == 'passed')
				return 'level3';

			if(this.level2.status == 'passed')
				return 'level2';
			
			if(this.level1.status == 'passed')
					return 'level1';

			if(this.model.status == 'passed')
				return 'model';
			
			return null;
		}

		get status()
		{
			if(this.level3.status == 'passed')
				return 'passed';

			if(this.level2.status == 'passed')
				return 'passed';
			
			if(this.level1.status == 'passed')
					return 'passed';

			if(this.model.status == 'passed')
				return 'passed';
			
			return null;
		}
		
		get grantedOn()
		{
			if(this.level3.status == 'passed')
				return this.level3.grantedOn;

			if(this.level2.status == 'passed')
				return this.level2.grantedOn;
			
			if(this.level1.status == 'passed')
				return this.level1.grantedOn;

			if(this.model.status == 'passed')
				return this.model.grantedOn;
			
			return null;
		}
		
		static Level = class {
			grantedOn = null
			grantType = null
			appliedOn = null

			get status()
			{
				if(this.grantedOn)
					return 'passed';

				if(this.appliedOn)
					return 'in-progress'

				return 'not-started';
			}
		}
	}

	static MAP = class {
		level1 = new UserView.MAP.Level1()
		level2 = new UserView.MAP.Level2()
		level3 = new UserView.MAP.Level3()

		get nextStage()
		{
			return this.level1.nextStage() 
				|| this.level2.nextStage() 
				|| this.level3.nextStage()
		}

		get level()
		{
			if(this.level3.status == 'passed')
				return 'level3';

			if(this.level2.status == 'passed')
				return 'level2';
			
			if(this.level1.status == 'passed')
				return 'level1';
			
			return null;
		}

		getAttempt(id) {
			let attempt = this.level1.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level2.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level2.stage2.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			attempt = this.level3.stage1.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;
			
			attempt = this.level3.stage2.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;
			
			attempt = this.level3.stage3.attempts.find(a => a.id == id);
			if(attempt)
				return attempt;

			return null;
		}

		static Attempt = class {
			id = null
			submittedOn = null

			date = null
			location = null
			name = null
			motorDesignations = null
			motorManufacturers = null
			type = null
			comments = null
			
			processedOn = null
			processedByUserId = null
			result = null
			reason = null
		}

		static Stage = class {
			attempts = []

			get status()
			{
				if(this.attempts.length == 0)
					return 'not-started';
				
				if(this.attempts.filter(a => a.result == 'passed').length > 0)
					return 'passed'

				if(this.attempts[this.attempts.length - 1].result == null)
					return 'pending'

				return 'failed';
			}

			get badgeCount()
			{
				return this.attempts.filter(a => a.result == 'passed').length;
			}

			get passedOn()
			{
				if(!this.attempts.length)
					return null;

				if(this.attempts[this.attempts.length - 1].result == 'passed')
					return this.attempts[this.attempts.length - 1].submittedOn;

				return null;
			}
		}

		static Level1 = class {
			grantedOn = null
			stage1 = new UserView.MAP.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';

				if(this.stage1.status == 'not-started')
					return 'not-started';

				if(this.stage1.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'MAP1S1';

				return null;
			}

			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage1.passedOn;
				}
			}
		}

		static Level2 = class {
			grantedOn = null
			stage1 = new UserView.MAP.Stage()
			stage2 = new UserView.MAP.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending' 
					|| this.stage2.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';
				
				if(this.stage1.status == 'not-started')
					return 'not-started';
				
				if(this.stage2.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get badgeCount()
			{
				return this.stage2.badgeCount;
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'MAP2S1';

				if(this.stage2.status != 'passed')
					return 'MAP2S2';

				return 'MAP2SX';
			}
			
			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage2.passedOn
				}
			}
		}

		static Level3 = class {
			grantedOn = null;

			stage1 = new UserView.MAP.Stage()
			stage2 = new UserView.MAP.Stage()
			stage3 = new UserView.MAP.Stage()

			get isAwaitingApproval()
			{
				return this.stage1.status == 'pending' 
					|| this.stage2.status == 'pending' 
					|| this.stage3.status == 'pending';
			}

			get status()
			{
				if(this.grantedOn)
					return 'passed';

				if(this.stage1.status == 'not-started')
					return 'not-started';

				if(this.stage3.status == 'passed')
					return 'passed';

				return 'in-progress';
			}

			get nextStage()
			{
				if(this.stage1.status != 'passed')
					return 'MAP3S1';

				if(this.stage2.status != 'passed')
					return 'MAP31S2';

				if(this.stage3.status != 'passed')
					return 'MAP31S3';

				return 'MAP3SX';
			}

			get passedOn()
			{
				if(this.status == 'passed')
				{
					if(this.grantedOn)
						return this.grantedOn;

					return this.stage3.passedOn
				}
			}
		}
	}

	static Membership = class {
		number = null
		startedOn = null
		expiresOn = null

		get status()
		{
			if(this.startedOn == null)
				return 'non-member'
			
			if(this.expiresOn != null && this.expiresOn < DateTime.now)
				return 'expired'

			return 'active';
		}
	}
}