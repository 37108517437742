<script>
import { getUserView, getUserEvents, getUserRocketEvents } from '../../js/readModel.js';
import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js';

export const pageName = 'admin-user-history';
export const pageRoute = '/admin/user/{userId}/history';
export const title = 'View User History'
export const requireAdmin = true;

export default {
	data() {
		return { 
			user: null,
			events: [],
			ready: false
		}
	},
	methods: {
		rsoLevelToLevel,
		decamelcase(input)
		{
			const result = input.replace(/([A-Z])/g, " $1");
			const finalResult = result.charAt(0).toUpperCase() + result.slice(1);	
			return finalResult;
		},
		async show(opts)
		{
			this.user = opts.user || await getUserView(opts.userId);
			let events = await Promise.all([getUserEvents(this.user.id), getUserRocketEvents(this.user.id)])
			this.events = events[0].concat(events[1]);
			this.events.sort((a,b) => a.occurredOn - b.occurredOn)
			this.ready = true;
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form ref="form" @submit.prevent="submit">
		<div class="row justify-content-center py-5">
			<div class="col-12 col-md-8">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">History</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="event in events" class="list-group-item">
								<div class="small">
									<strong>{{ decamelcase(event.type) }}</strong><br/>
									<pre>{{ JSON.stringify(event.data, null, 4) }}</pre>
								</div>
								<small class="text-muted small">{{ event.occurredOn.toLocaleString() }} by <a :href="`/admin/user/${event.issuingUserId}`">{{ event.issuingUserId == this.user.id ? 'Self' : 'Admin' }}</a></small>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col col-md-4">
				<div class="card">
					<div class="card-body">

						<a :href="`/admin/user/${user.id}`" class="avatar avatar-xl card-avatar">
							<img :src="user.avatar" class="avatar-img rounded-circle border border-4 border-card" alt="...">
						</a>

						<h2 class="card-title text-center">
							<a :href="`/admin/user/${user.id}`">{{ user.name }}</a>
						</h2>

						<!-- <p class="small text-center text-body-secondary mb-3">
							Woprking on the latest API integration.
						</p> -->

						<p class="text-center mb-4">
							<span :class="`badge mx-2 ${user.certification.level1.status == 'passed' ? 'badge-success' : (user.certification.level1.status == 'in-progress' ? 'badge-secondary' : 'badge-light') }`">Level 1</span>
							<span :class="`badge mx-2 ${user.certification.level2.status == 'passed' ? 'badge-success' : (user.certification.level2.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 2</span>
							<span :class="`badge mx-2 ${user.certification.level3.status == 'passed' ? 'badge-success' : (user.certification.level3.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 3</span>
							<span v-if="user.rso.level" class="badge mx-2 badge-success">{{ rsoLevelToLevel(user.rso.level) }} RSO</span>
						</p>

						<p class="text-center mb-4">
							<span :class="`badge mx-2 ${user.map.level1.status == 'passed' ? 'badge-success' : (user.map.level1.status == 'in-progress' ? 'badge-secondary' : 'badge-light') }`">MAP Level 1</span>
							<span :class="`badge mx-2 ${user.map.level2.status == 'passed' ? 'badge-success' : (user.map.level2.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">MAP Level 2</span>
							<span :class="`badge mx-2 ${user.map.level3.status == 'passed' ? 'badge-success' : (user.map.level3.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">MAP Level 3</span>
						</p>
						
						<div class="list-group list-group-flush">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Joined</small>
									</div>
									<div class="col-auto">
										<time class="small text-body-secondary" :datetime="user.createdOn?.format('yyyy-MM-dd')">
											{{ user.createdOn?.format('yyyy/MM/dd') }}
										</time>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Number</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.number }}
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>BMFA</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.BMFANumber }}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
</template>