import sha1 from 'sha1'

export function countPasswordBreaches(password)
{
	var sha = sha1(password).toUpperCase();

	var shaStart = sha.substr(0, 5);
	var shaSuffix = sha.substr(5);

	var timeout = null;

	return new Promise(resolve => {
		timeout = window.setTimeout(() => {
			console.error('Timeout calling HIBP service');
			resolve(0);
		}, 2000);

		fetch('https://api.pwnedpasswords.com/range/' + shaStart)
			.then(r => {
				window.clearTimeout(timeout);
				return r;
			})
			.then(r => r.text())
			.then(text => {
				var lines = text.split('\n');
				var records = lines.map(line => {
					var parts = line.split(':');
					return {
						shaSuffix: parts[0],
						count: parts[1]
					};
				}).filter(r => r.shaSuffix == shaSuffix);

				if(records.length == 0)
					resolve(0);

				resolve(parseInt(records[0].count, 10));
			})
			.catch(e => {
				clearTimeout(timeout);
				resolve(0);
			})

	})
}