import DateTime from "../../lib/dateTime.js"

export default class UserLite {
	id = null
	number = null
	name = null
	BMFANumber = null
	email = null
	avatar = null
	hasSetAvatar = false
	bornOn = null
	isAdmin = false
	certification = new UserLite.Certification();
	rso = new UserLite.RSO();
	map = new UserLite.MAP();
	membership = new UserLite.Membership();
	
	// Keep in sync with userView
	get isComplete()
	{
		return this.BMFANumber != null 
				&& this.bornOn != null
				&& this.hasSetAvatar;
	}

	static Certification = class {
		level1 = false
		level2 = false
		level3 = false
	}
	static RSO = class {
		model = false
		level1 = false
		level2 = false
		level3 = false
	}

	static MAP = class {
		level1 = false
		level2 = false
		level3 = false
	}

	static Membership = class {
		startedOn = null
		expiresOn = null
		
		get status()
		{
			if(this.startedOn == null)
				return 'non-member'
			
			if(this.expiresOn != null && this.expiresOn < DateTime.now)
				return 'expired'

			return 'active';
		}
	}
}