<script>
import DateTime from '../../../functions/lib/dateTime.js';
import { functions } from '../../js/lib/functions.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';

export const pageName = 'admin-import';
export const pageRoute = '/admin/import';
export const title = 'Import Members'
export const requireAdmin = true;

function parseDate(date)
{
	try {
		if(!date || !date.length)
			return null;

		let dateParts = date.split('/').map(p => parseInt(p, 10));
		let result = new DateTime(dateParts[2], dateParts[1], dateParts[0])
		if(result.valueOf() == NaN)
			return null;

		return result;
	} catch (error) {
		return null;
	}
}

function parseInteger(int)
{
	if(int === null || int === undefined || int.length == 0)
		return 0;

	let result = parseInt(int, 10);
	if(result == NaN)
		return 0;

	return result;
}

function parseCSV(csv)
{
	let rows = csv.split('\n').map(r => r.trim());

	let headersArray = rows[0].split(',').map(h => h.trim());

	let headersMap = headersArray.reduce((p, c, i) => {
		p[c] = i;
		return p
	}, {});

	rows = rows.slice(1)

	let members = rows.map(r => {
		let cols = r.split(',').map(c => c.trim());

		if(cols.length <= 1)
			return null;

		return {
			number: parseInt(cols[headersMap['UKRANumber']], 10),
			BMFANumber: cols[headersMap['BMFANumber']],
			name: cols[headersMap['FirstName']]  + ' ' + cols[headersMap['LastName']],
			surname: cols[headersMap['LastName']],
			email: cols[headersMap['EmailAddress1']].toLowerCase(),
			bornOn: parseDate(cols[headersMap['DOB']]),
			createdOn: parseDate(cols[headersMap['MemberSince']] || cols[headersMap['L1Date']] || '2024/08/21'),
			l1Date: parseDate(cols[headersMap['L1Date']]),
			l1Pass: parseInteger(cols[headersMap['L1Pass']]) != 0,
			l2Date: parseDate(cols[headersMap['L2Date']]),
			l2Pass: parseInteger(cols[headersMap['L2Pass']]) != 0,
			l3Date: parseDate(cols[headersMap['L3Date']]),
			l3Pass: parseInteger(cols[headersMap['L3Pass']]) != 0,
			rsoDate: parseDate(cols[headersMap['RSODate']]),
			rsoPass: parseInteger(cols[headersMap['RSOPass']]) != 0
		}
	}).filter(x => x != null)

	return members;
}

export default {
	data() {
		return { 
			ready: true
		}
	},

	methods: {
		upload: async function(e) {
			
			const reader = new FileReader();
			reader.addEventListener('load', async () => {
				let json = parseCSV(reader.result);
				
				let created = {},
					cert = {}
				
				for(var i = 0; i < json.members.length; i++)
				{
					let member = json.members[i];
					
					if(member.createdOn)
					{
						if(!created[member.createdOn.format('yyyy/MM')])
							created[member.createdOn.format('yyyy/MM')] = 0;

						created[member.createdOn.format('yyyy/MM')]++;
					}
					
					
					if(member.l1Date)
					{
						if(!created[member.l1Date.format('yyyy/MM')])
							cert[member.l1Date.format('yyyy/MM')] = 0;

						cert[member.l1Date.format('yyyy/MM')]++;
					}

					
					if(member.l2Date)
					{
						if(!created[member.l2Date.format('yyyy/MM')])
							cert[member.l2Date.format('yyyy/MM')] = 0;

						cert[member.l2Date.format('yyyy/MM')]++;
					}

					
					if(member.l3Date)
					{
						if(!created[member.l3Date.format('yyyy/MM')])
							cert[member.l3Date.format('yyyy/MM')] = 0;

						cert[member.l3Date.format('yyyy/MM')]++;
					}
				}

				let csv = ''
				for(let key in Object.keys(created))
				{
					csv += key + '/01,' + created[key] + ',' + cert[key] ? cert[key] : 0
					
					delete cert[key];
				}

				for(let key in Object.keys(cert))
				{
					csv += key + '/01,0' + cert[key]
					
					delete cert[key];
				}

			})
			reader.readAsText(this.$refs.fupMembers.files[0])
			
			
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">

			<div class="col">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="upload">
							<legend>Upload</legend>
							<div class="row">
								<div class="col">
									<div class="form-group mb-0">
										<input type="file" class="form-control" ref="fupMembers" placeholder="Members CSV" required />
										<span class="invalid-feedback"></span>
									</div>
								</div>
								<div class="col-auto">
									<button type="submit" class="btn btn-primary">Upload</button>
								</div>
							</div>
						</form>		
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
