import DateTime from '../../../functions/lib/dateTime.js';
import { showLoading, hideLoading } from './loading.js';
import Modal from './modal.js';

var $dialog = null, dialog = null;
var $buttons = null;
var $buttonTemplate = null;
var $btnClose = null;

export function showInput(options)
{
	return new Promise((resolve) => {
		if (!$dialog)
		{
			$dialog = document.$('.js-input-dialog');
			dialog = new Modal($dialog[0]);

			$btnClose = $dialog.$('.js-close')
			
			$buttons = $dialog.$('.js-buttons');

			$buttonTemplate = $dialog.$('.js-button-template');
			$buttonTemplate.remove();

			$dialog.$('form').addEventListener('submit', async e => {
				e.preventDefault();

				showLoading();

				let response = undefined;
				if(e.submitter?.action)
				{
					let value = $input.value;
					if(options.input.type == 'date')
						value = new DateTime(value);
					else if (options.input.type == 'number')
						value = parseFloat(value);

					response = await Promise.resolve(e.submitter.action(value));
				}
				
				dialog.hide()

				resolve(response);

				hideLoading();
			}, false);
		}
		
		$dialog.$('.js-title').textContent = options.title;
		$dialog.$('.js-message').innerHTML = options.message;
		
		$btnClose.classList.toggle('d-none', options.dismissable === false)
		
		$dialog.$('label').textContent = options.input.label
		let $input = $dialog.$('input');
		$input.type = options.input.type
		$input.min = options.input.min
		$input.max = options.input.max
		$input.required = options.input.required
		$input.value = options.input.value
		
		$buttons.innerHTML = '';
		for(var b = 0; b < options.buttons.length; b++)
		{
			var $col = $buttonTemplate.cloneNode(true);
			let $button = $col.$('button');

			$button.classList.add('btn-' + (options.buttons[b].style ?? 'outline-primary'));
			let action = options.buttons[b].action;
			$button.type = action ? 'submit' : 'button';

			$button.textContent = options.buttons[b].text;
			$button.action = action;

			if(!action)
			{
				$button.addEventListener('click', async e => {
					dialog.hide()
					resolve(undefined)
				});
			}

			$buttons.appendChild($col);
		}

		$input.setCustomValidity('');
		$input.reportValidity();

		dialog.show(options.dismissable !== false);
	})
}
