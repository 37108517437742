
import { firebase as firebaseConfig, recaptchaKey } from "../../config.js"
import { initializeApp } from 'firebase/app';
import { CustomProvider, initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

export function initFirebase() {

	const app = initializeApp(firebaseConfig);

	if (location.hostname === 'localhost') {

		const functions = getFunctions(app, 'europe-west1');
		connectFunctionsEmulator(functions, "localhost", 5001);

		const db = getFirestore(app);
		connectFirestoreEmulator(db, 'localhost', 8080);

		const auth = getAuth(app);
		connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });

		const storage = getStorage(app)
		connectStorageEmulator(storage, 'localhost', 9199)

		initializeAppCheck(app, {
			provider: new CustomProvider({
				getToken: () => {
					return Promise.resolve({
					token: "fake-token",
					expireTimeMillis: Date.now() + 1000 * 60 * 60 * 24,
					});
				}
			}),
			isTokenAutoRefreshEnabled: true,
		});
	} else {
		initializeAppCheck(app, {
			provider: new ReCaptchaV3Provider(recaptchaKey),
			isTokenAutoRefreshEnabled: true
		});
	}
}