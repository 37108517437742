<script>
import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js'
import { navigate, PageShowError } from '@trullock/page-manager'
import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'
import UserView from '../../../functions/domain/users/userView.js';
import { userChanged } from '../../js/auth.js'
import DateTime from '../../../functions/lib/dateTime.js';
import { showToast } from '../../js/utils/toast.js'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { getProjection } from '../../js/lib/client-read-model.js';
import { getAuth } from "firebase/auth";

export const pageName = 'account-complete';
export const pageRoute = '/account/complete';
export const title = 'Complete Account';
export const requireEmailVerified = false;

export default {
	data() {
		return { 
			user: null,
			name: '',
			email: '',
			bornOn: null,
			BMFANumber: '',
			BMFASurname: '',
			avatar: null,
			files: [],
			completeMode: false,
			ready: false,
			maxDob: DateTime.today,
			minDob: DateTime.today.addYears(-120)
		}
	},
	methods: {
		async boot(opts) {
			this.modal = new Modal(this.$refs.cropper);
		},
		async show()
		{
			functions.warmUp.userChangeName();
			functions.warmUp.userChangeBMFA();
			functions.warmUp.userChangeBornOn();
			functions.warmUp.userChangeAvatar();

			this.user = await getProjection([UserView, getCurrentUserId()]);

			if(this.user.isComplete)
			{
				// cant navigate() inside a show()
				throw new PageShowError('/', 'Account already complete', {}, 'replace');
			}

			this.avatar = this.user.avatar;
			this.bornOn = this.user.bornOn?.format('yyyy-MM-dd');
			this.BMFANumber = this.user.BMFANumber
			this.BMFASurname = this.user.BMFASurname;
			this.files = []

			this.ready = true;
		},
		async submit() {
			showLoading();

			this.$refs.fupAvatar.setCustomValidity('');
			this.$refs.bmfaNumber.setCustomValidity('');
			this.$refs.bmfaSurname.setCustomValidity('');

			if(!this.user.hasSetAvatar && !this.newAvatar)
			{
				this.$refs.fupAvatar.setCustomValidity('Please provide photo ID')
				this.$refs.fupAvatar.reportValidity();
				hideLoading();
				return;
			}

			let result = await functions.queryBMFA({
				surname: this.BMFASurname,
				number: this.BMFANumber
			})

			if(result.success)
			{
				if(result.status == 'expired')
				{
					this.$refs.bmfaSurname.setCustomValidity(' ');
					this.$refs.bmfaSurname.reportValidity();

					this.$refs.bmfaNumber.setCustomValidity('Insurance is not active');
					this.$refs.bmfaNumber.reportValidity();

					hideLoading();
					return;
				}
				else if(result.status == 'not-found')
				{
					this.$refs.bmfaSurname.setCustomValidity(' ');
					this.$refs.bmfaSurname.reportValidity();

					this.$refs.bmfaNumber.setCustomValidity('Insurance details not found');
					this.$refs.bmfaNumber.reportValidity();

					hideLoading();
					return;
				}
			}

			let updated = userChanged(u => u.isComplete)

			// copy these because the subscription can update them mid-submit
			let newNumber = this.BMFANumber;
			let newBMFASurname = this.BMFASurname;
			let newBornOn = new DateTime(this.bornOn);

			result = await functions.userChangeBMFA({ 
				number: newNumber,
				surname: newBMFASurname
			});
			if(!result.success)
			{
				showToast({ message: 'Error changing your BMFA details', style: 'bg-danger'})
				hideLoading();
				return;
			}

			result = await functions.userChangeBornOn({ bornOn: newBornOn })
			if(!result.success)
			{
				showToast({ message: 'Error changing your date of bith', style: 'bg-danger'})
				hideLoading();
				return;
			}

			if(this.newAvatar)
			{
				result = await functions.userChangeAvatar({ avatar64: this.newAvatar});
				if(!result.success)
				{
					showToast({ message: 'Error changing your avatar', style: 'bg-danger'})
					hideLoading();
					return;
				}
			}
			
			// Force an update as server-side updates don't seem to propagate to the client
			await getAuth().currentUser.reload();
			await updated;
			
			navigate('goal')

			showToast({
				message: 'Account completed',
				style: 'bg-success'
			})

			hideLoading();
		},
		hide()
		{
			this.$refs.fupAvatar.value = null
			this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
		},
		crop() {
			showLoading();
			
			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
					
				let canvas = this.cropper.getCroppedCanvas({
					width: 240,
					height: 240
				});

				this.avatar = canvas.toDataURL();
				this.newAvatar = this.avatar;

				canvas.toBlob(blob => {
					this.$refs.fupAvatar.blobData = {
						blob: blob,
						name: 'avatar.jpg'
					};
					this.$refs.lblAvatar.innerText = this.$refs.lblAvatar.pendingName;
				});
			
				hideLoading();
			}, 1);
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {
			
			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupAvatar.value = '';
				this.$refs.fupAvatar.blobData = null;
				this.$refs.imgCropper.src = url;

				let $img = new Image();
				$img.addEventListener('load', e => {
					this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
					this.$refs.lblAvatar.pendingName = name;
					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'

					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						aspectRatio: 1,
						viewMode: 1,
						autoCropArea: 1
					});
				})

				$img.addEventListener('error', e => {
					showToast({
						message: 'File not a recognised image',
						style: 'bg-danger'
					})
				})

				$img.src = url;
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form @submit.prevent="submit">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Complete account</h1>
				<p class="text-muted text-center mb-5">Please complete your account to continue.</p>
				
				<fieldset>
					<div class="form-group">
						<label>Date of birth</label>
						<input type="date" class="form-control" v-model="bornOn" required :max="`${maxDob.format('yyyy-MM-dd')}`" :min="`${minDob.format('yyyy-MM-dd')}`"/>
						<span class="invalid-feedback"></span>
					</div>
				</fieldset>
				<fieldset>
					<div class="field form-group mb-0">
						<label class="d-block">
							Photo ID
							
							<div class="row justify-content-center">
								<div class="col-auto">
									<div class="dz-default dz-message pb-4">
										<div class="avatar avatar-xxl">
											<img ref="imgAvatar" class="avatar-img rounded-circle" :src="avatar" alt="Your Photo ID">
										</div>
									</div>
								</div>
							</div>
						</label>
						<div class="alert alert-secondary small"><span class="fe fe-alert-triangle"></span> Your picture must be a good quality portrait of yourself and will be used for membership verification at launches.</div>
						<div class="custom-file">
							<input type="file" @change="inputChanged" ref="fupAvatar" class="custom-file-input" name="Avatar" accept="image/*">
							<label ref="lblAvatar" class="custom-file-label text-muted">Choose image&hellip;</label>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text text-muted small">Must be a jpeg or png</span>
					</div>
				</fieldset>
				
				<hr class="my-5">
				<fieldset class="mb-4">
					<div class="row">
						<div class="col">
							<div class="form-group mb-0">
								<label>BMFA Number</label>
								<input type="text" class="form-control" ref="bmfaNumber" v-model="BMFANumber" required placeholder="e.g. 123456" />
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label>Surname</label>
								<input type="text" class="form-control" ref="bmfaSurname" v-model="BMFASurname" required placeholder="e.g. Smith" />
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<span class="form-text text-muted small">Provide your number and surname from your BMFA insurance. You must have valid BMFA insurance to join UKRA: <a href="https://bmfa.org/join-us" target="_blank">https://bmfa.org/join-us</a></span>
				</fieldset>
				<button type="submit" class="btn btn-lg btn-primary btn-block">Complete account <span class="fe fe-check-circle"></span></button>
			</div>
		</div>
	</form>
</div>
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="img-container">
                        <img ref="imgCropper" :src="avatar" class="">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>