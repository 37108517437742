import { showLoading, hideLoading } from './loading.js';
import Modal from './modal.js';

var $dialog = null, dialog = null;
var $buttons = null;
var $buttonTemplate = null;
var $btnClose = null;

export function showConfirm(options)
{
	return new Promise((resolve) => {
		if (!$dialog)
		{
			$dialog = document.$('.js-confirm-dialog');
			dialog = new Modal($dialog[0]);

			$btnClose = $dialog.$('.js-close')
			
			$buttons = $dialog.$('.js-buttons');

			$buttonTemplate = $dialog.$('.js-button-template');
			$buttonTemplate.remove();
		}
		
		$dialog.$('.js-title').textContent = options.title;
		$dialog.$('.js-message').innerHTML = options.message;
		
		$btnClose.classList.toggle('d-none', options.dismissable === false)
		
		$buttons.innerHTML = '';
		for(var b = 0; b < options.buttons.length; b++)
		{
			var $col = $buttonTemplate.cloneNode(true);
			let $button = $col.$('button');

			$button.classList.add('btn-' + (options.buttons[b].style ?? 'outline-primary'));

			$button.textContent = options.buttons[b].text;
			let action = options.buttons[b].action;
			$button.addEventListener('click', async e => {
				$dialog.$('button').disabled = true;
				showLoading();

				let response = undefined;
				if(action)
				{
					let result = action()
					response = await Promise.resolve(result);
				}
				
				dialog.hide()

				resolve(response);

				hideLoading();
			});

			$buttons.appendChild($col);
		}

		dialog.show(options.dismissable !== false);
	})
}
