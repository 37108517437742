import { v4 as newId } from 'uuid'

export function connectFormComponents($parent)
{
	if(!$parent)
		return;

	let $groups = [...$parent.querySelectorAll('.form-group')]

	$groups.forEach($group => {
		let $btnGroup = $group.querySelector('.btn-group-toggle');

		if($btnGroup)
		{
			let $spans = [...$btnGroup.querySelectorAll('span')]

			let descriptors = [];

			let $feedbacks = [...$group.querySelectorAll('.valid-feedback, .feedback, .invalid-feedback')];
			$feedbacks.forEach($feedback => descriptors.push($feedback.id = newId()))

			let $helps = [...$group.querySelectorAll('span.form-text')];
			$helps.forEach($help => descriptors.push($help.id = newId()))

			$spans.forEach($span => {
				let $input = $span.querySelector('input');
				let $label = $span.querySelector('label');
		
				$input.id = newId();
				$label.htmlFor = $input.id;
		
				$input.setAttribute('aria-describedby', descriptors.join(' '))
			})
		}
		else
		{
			let $input = $group.querySelector('input, select, textarea');
			let $labels = [...$group.querySelectorAll('label')];
	
			$input.id = newId();
			$labels.forEach($label => $label.htmlFor = $input.id);
	
			let descriptors = [];
	
			let $feedbacks = [...$group.querySelectorAll('.valid-feedback, .feedback, .invalid-feedback')];
			$feedbacks.forEach($feedback => descriptors.push($feedback.id = newId()))
			
			let $helps = [...$group.querySelectorAll('span.form-text')];
			$helps.forEach($help => descriptors.push($help.id = newId()))
			
			$input.setAttribute('aria-describedby', descriptors.join(' '))
		}
	})
}