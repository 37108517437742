export const validation = {
	'error': 'An error occurred, please try again',
	'auth/email-already-in-use': "Already in use",
	'auth/email-already-exists': "Already in use",
	'auth/invalid-email': "Not a valid email address",
	'auth/invalid-login-credentials': 'Incorrect email or password',
	'auth/weak-password': "Password is not strong enough",
	'auth/wrong-password': "Password is incorrect",
	'auth/user-not-found': 'Email address not found',
	'auth/user-disabled': 'Account is disabled',
	'auth/account-exists-with-different-credential': 'This email address is already associated with a different sign-in mechanism. Sign in with a previous mechanism first.',
	'auth/no-password': 'This email address is associated with a different sign-in mechanism.',
	messages: {
		required: 'Required',
		invalidEmail: 'Not a valid email address',
		minLength: l => 'Must be at least ' + l + ' characters',
		invalidFormat: 'Not in the required format',
		breached: n => 'This is a common password, choose something more unique.'
	}
}

export const toast = {
	'password-changed': 'Your password has been changed'
}

export const authProviders = {
	'password': 'Password',
	'google.com': 'Google',
	'github.com': 'Github'
}