import DateTime from "../lib/dateTime.js";


export function getMembershipImportDate()
{
	return new DateTime(process.env.IMPORT_DATE);
}

export function getMembershipChangeoverDate()
{
	return new DateTime(process.env.MEMBERSHIP_LIVE_DATE);
}

export function environment()
{
	let fnEmulator = process.env.FUNCTIONS_EMULATOR;
	return fnEmulator === true || fnEmulator === 'true' ? 'dev' : 'prod'
}

export function getSESRateLimit()
{
	return parseInt(process.env.SES_RATE_LIMIT, 10);
}

export function getHostPrefix()
{
	return process.env.HOST_PREFIX;
}

export function getRSOInterviewEmail()
{
	return process.env.RSO_INTERVIEW_EMAIL;
}

export function getMembershipEmail()
{
	return process.env.MEMBERSHIP_EMAIL;
}

export function getIdentityToolkitUrlPrefix()
{
	return process.env.IDTOOLKIT_URL
}

export function getFirebaseApiKey()
{
	return process.env.FB_API_KEY;
}

export function getGoogleWalletIssuerId()
{
	return process.env.GOOGLEWALLET_ISSUERID;
}

export function getGoogleWalletClassId()
{
	// Classes cant be deleted, so when we want a new class we have to increment this
	return process.env.GOOGLEWALLET_CLASSID;
}

export function getBMFACreds()
{
	return {
		user: process.env.BMFA_USER,
		pass: process.env.BMFA_PASS
	}
}

export function getStripeSK(mode)
{
	if(mode == 'live' || mode == 'live-test')
		return process.env.STRIPE_KEY

	return process.env.STRIPE_KEY_TEST
}

export function getStripeWebHookSecret()
{
	return process.env.STRIPE_WEBHOOK_SECRET;
}

export function getPriceId(senior, renewal, mode)
{
	if(mode == 'test')
	{
		if(senior)
		{
			if(renewal)
			{
				return {
					priceId: process.env.STRIPE_SNR_RENEWAL_PRICEID_TEST,
					description: 'Senior Membership Renewal TEST'
				};
			}
			else
			{
				return { 
					priceId: process.env.STRIPE_SNR_INITIAL_PRICEID_TEST,
					description: 'Senior Membership TEST'
				}
			}
		}
		
		if(renewal)
		{
			return {
				priceId: process.env.STRIPE_JNR_RENEWAL_PRICEID_TEST,
				description: 'Junior Membership Renewal TEST'
			};
		}
		else
		{
			return {
				priceId: process.env.STRIPE_JNR_INITIAL_PRICEID_TEST,
				description: 'Junior Membership TEST'
			}
		}
	}

	if(mode == 'live-test')
	{
		return {
			priceId: process.env.STRIPE_TEST_PRICEID,
			description: 'Live Test Membership'
		};
	}

	if(senior)
	{
		if(renewal)
		{
			return {
				priceId: process.env.STRIPE_SNR_RENEWAL_PRICEID,
				description: 'Senior Membership Renewal'
			};
		}
		else
		{
			return {
				priceId: process.env.STRIPE_SNR_INITIAL_PRICEID,
				description: 'Senior Membership'
			};
		}
	}
	
	if(renewal)
	{
		return {
			priceId: process.env.STRIPE_JNR_RENEWAL_PRICEID,
			description: 'Junior Membership Renewal'
		};
	}
	else
	{
		return {
			priceId: process.env.STRIPE_JNR_INITIAL_PRICEID,
			description: 'Junior Membership'
		};
	}
}

export function getCardPriceId(mode)
{
	if(mode == 'test')
	{
		return {
			priceId: process.env.STRIPE_CARD_PRICEID_TEST,
			description: 'Membership Card TEST'
		};	
	}

	if(mode == 'live-test')
	{
		return {
			priceId: process.env.STRIPE_TEST_CARD_PRICEID,
			description: 'Test Membership Card'
		};
	}

	return {
		priceId: process.env.STRIPE_CARD_PRICEID,
		description: 'Membership Card'
	};
}


export function getCertificatePriceId(mode, type)
{
	if(type == 'level1')
	{
		if(mode == 'test')
		{
			return {
				priceId: process.env.STRIPE_CERT_L1_PRICEID_TEST,
				description: 'L1 Certificate TEST'
			};	
		}

		if(mode == 'live-test')
		{
			return {
				priceId: process.env.STRIPE_TEST_CERT_L1_PRICEID,
				description: 'Test L1 Certificate'
			};	
		}

		return {
			priceId: process.env.STRIPE_CERT_L1_PRICEID,
			description: 'L1 Certificate'
		};	
		
	}
	
	if(type == 'level2')
	{
		if(mode == 'test')
		{
			return {
				priceId: process.env.STRIPE_CERT_L2_PRICEID_TEST,
				description: 'L2 Certificate TEST'
			};	
		}

		if(mode == 'live-test')
		{
			return {
				priceId: process.env.STRIPE_TEST_CERT_L2_PRICEID,
				description: 'Test L2 Certificate'
			};	
		}

		return {
			priceId: process.env.STRIPE_CERT_L2_PRICEID,
			description: 'L2 Certificate'
		};
	}
		
	if(type == 'level3')
	{
		if(mode == 'test')
		{
			return {
				priceId: process.env.STRIPE_CERT_L2_PRICEID_TEST,
				description: 'L3 Certificate TEST'
			};	
		}

		if(mode == 'live-test')
		{
			return {
				priceId: process.env.STRIPE_TEST_CERT_L2_PRICEID,
				description: 'Test L2 Certificate'
			};	
		}
			
		return {
			priceId: process.env.STRIPE_CERT_L2_PRICEID,
			description: 'L2 Certificate'
		};
	}

	if(type == 'rso')
	{
		if(mode == 'test')
		{
			return {
				priceId: process.env.STRIPE_CERT_RSO_PRICEID_TEST,
				description: 'RSO Certificate TEST'
			};	
		}
		
		if(mode == 'live-test')
		{
			return {
				priceId: process.env.STRIPE_TEST_CERT_RSO_PRICEID,
				description: 'Test RSO Certificate'
			};	
		}

		return {
			priceId: process.env.STRIPE_CERT_RSO_PRICEID,
			description: 'RSO Certificate'
		};
	}

	throw new Error(`Invalid cert type ${type}`)
}