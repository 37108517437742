<script>
export const pageName = 'account-change-email';
export const pageRoute = '/account/change-email';
export const title = 'Change email'
export const requireEmailVerified = false;

import { GoogleAuthProvider, fetchSignInMethodsForEmail, getAuth, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth'
import { navigate } from '@trullock/page-manager';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { validation } from '../../js/language.js'
import { functions } from '../../js/lib/functions.js'
import { getCurrentUserId, userChanged } from '../../js/auth.js';

export default {
	data() {
		return {
			user: null,
			requirePass: false,
			password: null,
			email: null,
			newEmail: null,
			ready: false
		 }
	},
	methods: {
		submit: async function() {
			let signInResult = null;

			this.$refs.newEmail.setCustomValidity('');
			this.$refs.newEmail.reportValidity();

			this.$refs.password?.setCustomValidity('');
			this.$refs.password?.reportValidity();

			if(this.requirePass)
			{
				showLoading();		

				try
				{
					signInResult = await signInWithEmailAndPassword(getAuth(), this.email, this.password)
				}
				catch(error)
				{					
					this.$refs.password.setCustomValidity(validation[error.code] || validation['error']);
					this.$refs.password.reportValidity();
					hideLoading();
					return;			
				}
			}
			else
			{
				showLoading('Waiting for response from authentication popup...');

				let currentUserId = getCurrentUserId();

				signInResult = await signInWithPopup(getAuth(), new GoogleAuthProvider())
				
				if(signInResult.user.uid != currentUserId)
				{
					await signOut(getAuth())

					showConfirm({
						title: 'Error changing email',
						message: 'When confirming your identity you logged in as a different user. The email address on either account has not been changed. We have signed you out to avoid confusion. You need to sign back in and start again.',
						dismissable: false,
						buttons: [
							{
								text: 'I understand',
								style: 'primary',
								action: async () => {
									
									// hard navigation to tear down JS envrionment
									window.location = '/';
								}
							}
						]
					})

					return;
				}
			}

			let userChangedP = userChanged(u => !!u);
			
			let result = await functions.userChangeEmail({ email: this.newEmail });

			if(result.success)
			{
				await userChangedP;

				// For some reason you have to force a reload. currentUser doesnt get updated otherwise and you retain the old email address
				await getAuth().currentUser.reload()

				showToast({
					message: 'Email changed, but needs verifying',
					style: 'bg-success'
				});

				navigate('/account/email-verify')
			}
			else
			{
				this.$refs.newEmail.setCustomValidity(validation[result.error] || validation['error']);
				this.$refs.newEmail.reportValidity();
			}
			
			hideLoading();
		},
		show: async function(opts) {
			this.email = getAuth().currentUser.email;
			this.newEmail = null;

			let methods = await fetchSignInMethodsForEmail(getAuth(), this.email)

			this.requirePass = methods.indexOf('password') == 0 && methods.length == 1;
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Change email</h1>
			<p class="text-muted text-center mb-5">Set a new email address.</p>
			<form @submit.prevent="submit">
				<fieldset class="mb-4">
					<legend>Account details</legend>
					<div v-if="requirePass" class="form-group mb-4">
							<label class="form-label">Current password</label>
							<div class="input-group has-validation">
								<input type="password" class="form-control" ref="password" v-model="password" name="current-password" autocomplete="current-password" required minlength="8" />
								<div class="input-group-append">
									<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
								</div>
								<span class="invalid-feedback"></span>
							</div>
						</div>
					<div class="form-group">
						<label>Current email address</label>
						<input type="email" class="form-control" v-model="email" disabled/>
					</div>
					<div class="form-group mb-4">
						<label>New email address</label>
						<input type="email" class="form-control" ref="newEmail" v-model="newEmail" autocomplete="off" placeholder="e.g. user@example.com" required />
						<span class="invalid-feedback"></span>
					</div>
				</fieldset>
				<button type="submit" class="btn btn-lg btn-block btn-primary mb-3">Change email <span class="fe fe-check-circle"></span></button>
			</form>
		</div>
	</div>
</div>
</template>