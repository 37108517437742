import Modal from './modal.js';

let $loadingDialog = null, loadingDialog = null;
let timer = null;
let messageIndex = 0;

let messages = [
	'Folding parachute...',
	'Packing wadding...',
	'Setting ejection delay...',
	'Loading motors...',
	'Fitting ignitor...',
	'Padding up...',
	'Arming electronics...',
	'Waiting for an RSO...',
	'Counting down...',
	'Having second thoughts...'
]
export function showLoading(message)
{
	if (!$loadingDialog)
	{
		$loadingDialog = document.$('.js-loading-dialog');
		loadingDialog = new Modal($loadingDialog);
	}

	if(timer)
		return;
	
	$loadingDialog.$('.js-message').innerHTML = message || messages[0];
	messageIndex = 0;
	loadingDialog.show();

	if(message)
		return;
	
	timer = setInterval(() => {
		messageIndex++;
		if(messageIndex == messages.length)
			messageIndex = 0;
		$loadingDialog.$('.js-message').innerHTML = messages[messageIndex];
	}, 1500);
}


export function hideLoading()
{
	clearInterval(timer);
	timer = null;
	loadingDialog.hide();
}