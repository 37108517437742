<script>

export const requireAuth = false;
export const pageName = 'account-sign-in';
export const pageRoute = '/account/sign-in';
export const title = 'Sign-in'

import { getAuth, GoogleAuthProvider, OAuthProvider , signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail, GithubAuthProvider, linkWithCredential } from 'firebase/auth'
import { validation } from '../../js/language.js'
import { getAdditionalUserInfo } from 'firebase/auth'
import { showToast } from '../../js/utils/toast.js';
import { navigate, PageShowError } from '@trullock/page-manager';
import { functions } from '../../js/lib/functions.js'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { userChanged } from '../../js/auth.js';

export default {
	data() {
		return {
			email: '',
			password: ''
		 }
	},
	methods: {
		resetAuthErrors: function(){
			this.$refs.btnGoogle.classList.remove('glow');
			this.$refs.btnGithub.classList.remove('glow');
			this.$refs.btnMicrosoft.classList.remove('glow');
			this.$refs.password.classList.remove('glow');
		},
		resetPassword: function(e){
			e.stopPropagation();
			navigate(e.target.getAttribute('href'), { email: this.email })
		},
		submit: async function() {
			this.resetAuthErrors();

			this.$refs.email.setCustomValidity('');
			this.$refs.password.setCustomValidity('');

			showLoading();

			try
			{
				let userChangedP = userChanged();

				let signInResult = await signInWithEmailAndPassword(getAuth(), this.email, this.password)

				await userChangedP;

				navigate('goal');

				if(this.pendingCred)
				{
					signInResult.user.linkWithCredential(this.pendingCred).then(result => {
						showToast({
							style: 'bg-success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}
			catch(error)
			{
				if(error.code == 'auth/wrong-password')
				{
					return fetchSignInMethodsForEmail(getAuth(), this.email).then(methods => {
						if(methods.indexOf('password') == -1)
						{
							this.glowAuthProviderMethods(methods);
							this.$refs.email.setCustomValidity(validation['auth/no-password']);
							this.$refs.email.reportValidity();
						}
						else
						{
							this.$refs.password.setCustomValidity(validation[error.code] || validation['error']);
							this.$refs.password.reportValidity();
						}

						hideLoading();
					});
				}
				
				this.$refs.email.setCustomValidity(validation[error.code] || validation['error']);
				this.$refs.email.reportValidity();			
			}

			hideLoading();
		},
		ssoGoogle: function() {
			var provider = new GoogleAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Google account');
		},
		ssoGithub: function() {
			var provider = new GithubAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Github account');
		},
		ssoMicrosoft: function() {
			var provider = new OAuthProvider('microsoft.com');
			this.signInWithSSO(provider, 'Account successfully linked to your Microsoft account');
		},
		signInWithSSO: function(provider, linkedMessage){
			this.resetAuthErrors();
			this.$refs.form.reset(); // to prevent chrome suggesting to save unused details in the form
			
			showLoading('Waiting for response from authentication popup...');

			// filter null because initially it "changes" to null as the listener starts looking for the new user that doesnt exist yet
			let userChangedP = userChanged(u => !!u);

			signInWithPopup(getAuth(), provider).then(async signInResult => {

				let info = getAdditionalUserInfo(signInResult);
				if(info.isNewUser)
				{
					showLoading('Creating account...');

					let result = await functions.userCreate({ name: signInResult.user.displayName })
					if(!result.success)
					{
						showToast({ message: 'Error creating your account', style: 'bg-danger'})
						return;
					}

					// refresh token for new users
					await signInResult.user.getIdToken(true);
				}
				else
				{
					// TODO: this is hacky, can we just do this in the backend when necessary?
					await functions.userUpdateEmailVerified();
				}

				await userChangedP;

				navigate('goal', { fallback: info.isNewUser ? '/account/complete' : '/'});

				if(this.pendingCred)
				{
					linkWithCredential(signInResult.user, this.pendingCred).then(result => {
						showToast({
							style: 'bg-success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}).catch(error => {

				if(error.code == 'auth/account-exists-with-different-credential')
				{
					this.handleExistingAuth(error, linkedMessage);
					return;
				}

				this.$refs.email.setCustomValidity(validation[error.code] || validation['error']);
				this.$refs.email.reportValidity();
			}).finally(() => {
				
				hideLoading();
			})

		},
		handleExistingAuth: function(error, linkedMessage){
			this.pendingCred = error.credential;
			this.pendingCredLinkedMessage = linkedMessage
			this.email = error.email;

			fetchSignInMethodsForEmail(getAuth(), error.email).then(methods => {
				this.glowAuthProviderMethods(methods);
			});

			this.$refs.email.setCustomValidity(validation[error.code] || validation['error']);
			this.$refs.email.reportValidity();
		},
		glowAuthProviderMethods: function(methods) {
			if(methods.indexOf('google.com') > -1)
				this.$refs.btnGoogle.classList.add('glow');
			if(methods.indexOf('github.com') > -1)
				this.$refs.btnGithub.classList.add('glow');
			if(methods.indexOf('microsoft.com') > -1)
				this.$refs.btnMicrosoft.classList.add('glow');
			if(methods.indexOf('password') > -1)
				this.$refs.password.classList.add('glow');
		},
		emailVerify: async function(){
			await functions.userEmailVerify();
		},

		show: function(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')
			
			functions.warmUp.userCreate();

			this.resetAuthErrors();
			this.pendingCred = null;
			this.pendingCredLinkedMessage = null;
			this.$refs.form.reset();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Sign in</h1>
			<p class="text-muted text-center mb-5">Welcome back.</p>
			<div class="text-center mb-2">
				<button @click.prevent="ssoGoogle" ref="btnGoogle" class="btn btn-white btn-auth"><img alt="Google icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" width="18" height="18"> Sign in with Google</button>
			</div>
			<div class="text-center mb-2 d-none">
				<button @click.prevent="ssoGithub" ref="btnGithub" class="btn btn-white btn-auth"><img alt="Github icon" src="../../img/github.svg" width="18" height="18"> Sign in with Github</button>
			</div>
			<div class="text-center mb-4 d-none">
				<button @click.prevent="ssoMicrosoft" ref="btnMicrosoft" class="btn btn-white btn-auth"><img alt="Microsoft icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg" width="18" height="18"> Sign in with Microsoft</button>
			</div>
			<hr class="mb-4" />
			<form @submit.prevent="submit" ref="form">
				<fieldset class="mb-4">
					<legend>Login credentials</legend>
					<div class="form-group mb-4">
						<label>Email</label>
						<input type="email" class="form-control" ref="email" v-model="email" autocomplete="email" placeholder="e.g. your.name@example.com" required tabindex="1" />
						<span class="invalid-feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label>Password</label>
						<div class="input-group has-validation">
							<input type="password" ref="password" v-model="password" placeholder="Your password" class="form-control form-control-appended" autocomplete="current-password" required minlength="8" tabindex="3">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
							</div>
							<span class="invalid-feedback"></span>
						</div>
					</div>
				</fieldset>
				<button type="submit" ref="btnSignIn" class="btn btn-lg btn-block btn-primary mb-3">Sign In</button>
				<div class="text-center">
					<p class="small text-muted mb-2"><a href="/account/reset-password" @click.prevent="resetPassword">Reset your password</a> if you've forgotten it.</p>
					<p class="small text-muted">Not got an account yet? <a href="/account/sign-up">Sign up</a> now.</p>
				</div>
			</form>
		</div>
	</div>
</div>
</template>