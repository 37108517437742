<script>
export const pageName = 'validate';
export const pageRoute = '/validate/{userId}';
export const title = 'Validate Membership'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js';
import { functions } from '../../js/lib/functions.js'

export default {
	data() {
		return { 
			user: null,
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			this.user = await functions.userMembershipValidate({ userId: opts.userId });
			this.ready = true;
		},
		stageBadgeText(stage)
		{
			return stage.status == 'pending' ? 'Pending' : 
				stage.status == 'passed' ? 'Passed' : 
				'Not started'
		},
		stageBadgeClass(stage)
		{
			return stage.status == 'pending' ? 'badge-secondary' : 
				stage.status == 'passed' ? 'badge-success' : 
				'badge-light'
		},
		attemptBadgeText(stage)
		{
			return stage.result == null ? 'Pending' : 
				stage.result == 'passed' ? 'Passed' : 
				'Failed'
		},
		attemptBadgeClass(stage)
		{
			return stage.result == null ? 'badge-secondary' : 
				stage.result == 'passed' ? 'badge-success' : 
				'badge-danger'
		},
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">

			<div class="col col-12 col-lg-8">
				<div class="card py-lg-7">
					<div class="card-body text-center">

						<div class="avatar avatar-xxl mb-3">
							<img :src="user.avatar" class="avatar-img rounded-circle border border-4 border-card" alt="...">
						</div>

						<h2 class="card-title">
							{{ user.name }}
						</h2>

						<p class="small text-secondary mb-3">
							<span v-if="user.membership.status == 'active'" class="badge badge-success">Active Member</span>
							<span v-if="user.membership.status == 'expired'" class="badge badge-danger">Expired Member</span>
							<span v-if="user.membership.status == 'non-member'" class="badge badge-danger">Non-Member</span>
						</p>
						<p class="small text-secondary mb-3" v-if="user.membership.status != 'non-member'">
							UKRA Number: {{  user.number }}
						</p>
						<p class="small text-secondary mb-3">
							{{  user.BMFANumber ? `BMFA Number: ${user.BMFANumber}` : null }}
						</p>

						<p class="card-text">
							<span v-if="user.certification.level1.status == 'passed'" class="badge badge-success mx-2">Level 1</span>
							<span v-if="user.certification.level2.status == 'passed'" class="badge badge-success mx-2">Level 2</span>
							<span v-if="user.certification.level3.status == 'passed'" class="badge badge-success mx-2">Level 3</span>
						</p>
						<p v-if="user.rso.level" class="card-text">
							<span class="badge mx-2 badge-success">{{ rsoLevelToLevel(user.rso.level) }} RSO</span>
						</p>
						<p class="card-text">
							<span v-if="user.map.level1.status == 'passed'" class="badge badge-success mx-2">MAP Level 1</span>
							<span v-if="user.map.level2.status == 'passed'" class="badge badge-success mx-2">MAP Level 2 <span v-if="user.map.level2.badgeCount > 1" v-for="i in user.map.level2.badgeCount - 1" class="fe fe-award"></span> </span>
							<span v-if="user.map.level3.status == 'passed'" class="badge badge-success mx-2">MAP Level 3 <span v-if="user.map.level3.badgeCount > 1" v-for="i in user.map.level3.badgeCount - 1" class="fe fe-award"></span> </span>
						</p>
						
					</div>
				</div>
				
				<div v-if="user.certification.level1.status == 'in-progress'" class="card">
					<div class="card-header">
						<h4 class="card-header-title">Level 1 Progress</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage1)}`">
											{{ stageBadgeText(user.certification.level1.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 2
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage2)}`">
											{{ stageBadgeText(user.certification.level1.stage2) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Certification Flight
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage3)}`">
											{{ stageBadgeText(user.certification.level1.stage3) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="user.certification.level2.status == 'in-progress'" class="card">
					<div class="card-header">
						<h4 class="card-header-title">Level 2 Progress</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.certification.level2.stage1)}`">
											{{ stageBadgeText(currentUser.certification.level2.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Certification Flight
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.certification.level2.stage2)}`">
											{{ stageBadgeText(currentUser.certification.level2.stage2) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
