import Page from '../../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { showToast } from '../../js/utils/toast.js';
import { functions } from '../../js/lib/functions.js';
import { getAuth, signInWithEmailLink } from 'firebase/auth';
import { userChanged } from '../../js/auth.js';

export default pageManager.registerPage('account-email-verify-complete', '/account/email-verify/complete', class extends Page {
	static requireAuth = false;
	static existingDomSelector = null;
	
	constructor()
	{
		super(null);
	}

	get title()
	{
		return 'Verifying...';
	}

	async show(opts)
	{
		let response = await functions.userCompleteEmailVerify({
			oobCode: opts.oobCode
		})

		if(!response.success)
		{
			console.error(response.error);

			showToast({
				message: 'Invalid verification code',
				style: 'bg-danger'
			})

			throw new pageManager.PageShowError('/', 'Invalid oobCode', {}, 'replace');
		}

		// filter null because initially it "changes" to null as the listener starts looking for the new user that doesnt exist yet
		let userChangedP = userChanged(u => !!u);

		let auth = getAuth();
		
		if(!auth.currentUser)
		{
			let result = await signInWithEmailLink(auth, response.email, response.loginUrl)
			
			if(result.user)
				await userChangedP;
		}
		
		showToast({
			message: 'Email successfully verfied',
			style: 'bg-success'
		})

		// cant navigate() inside a show()
		throw new pageManager.PageShowError('/', 'Email verified', {}, 'replace');
	}

	hide()
	{
		return Promise.resolve();
	}
})