import * as pageManager from '@trullock/page-manager';
import { createApp } from 'vue';
import Page from '../js/page.js';


import * as PageLoading from './loading.vue'
registerVuePage(PageLoading);
import * as PageError from './error.vue'
registerVuePage(PageError);
import * as PageError404 from './error-404.vue'
registerVuePage(PageError404);
import * as PageError403 from './account/forbidden.vue'
registerVuePage(PageError403);


import * as PagePrivacyPolicy from './privacy-policy.vue'
registerVuePage(PagePrivacyPolicy);
import * as PageTermsOfService from './terms-of-service.vue'
registerVuePage(PageTermsOfService);

import * as PageHelp from './dashboard/help.vue'
registerVuePage(PageHelp);



import * as PageDashboard from './dashboard/dashboard.vue'
registerVuePage(PageDashboard);


import * as PageValidate from './dashboard/validate.vue'
registerVuePage(PageValidate);

import * as PageMembership from './membership/membership.vue'
registerVuePage(PageMembership);
import './membership/result.js'

import * as PageMembershipBuyCard from './membership/buy-card.vue'
registerVuePage(PageMembershipBuyCard);
import './membership/buy-card-result.js'

import './account/sign-out.js';
import './account/email-verify-complete.js';

import * as PageAccountSignIn from './account/sign-in.vue'
registerVuePage(PageAccountSignIn);
import * as PageAccountSignUp from './account/sign-up.vue'
registerVuePage(PageAccountSignUp);
import * as PageAccountResetPassword from './account/reset-password.vue'
registerVuePage(PageAccountResetPassword);
import * as PageAccountSetPassword from './account/set-password.vue'
registerVuePage(PageAccountSetPassword);
import * as PageAccountSecurity from './account/security.vue'
registerVuePage(PageAccountSecurity);
import * as PageAccountChangeEmail from './account/change-email.vue'
registerVuePage(PageAccountChangeEmail);
import * as PageAccountPreferences from './account/account.vue'
registerVuePage(PageAccountPreferences);
import * as PageAccountComplete from './account/complete.vue'
registerVuePage(PageAccountComplete);
import * as PageAccountEmailVerify from './account/email-verify.vue'
registerVuePage(PageAccountEmailVerify);
import * as PageAccountDelete from './account/delete.vue'
registerVuePage(PageAccountDelete);
import * as PageAccountSAR from './account/subject-access-request.vue'
registerVuePage(PageAccountSAR);
import * as PageAccountPasswordStrength from './account/password-strength.vue'
registerVuePage(PageAccountPasswordStrength);


import * as PageCertLevel1 from './certification/level1.vue'
registerVuePage(PageCertLevel1);
import * as PageCertLevel2 from './certification/level2.vue'
registerVuePage(PageCertLevel2);
import * as PageCertLevel3 from './certification/level3.vue'
registerVuePage(PageCertLevel3);
import * as PageCertRSO from './certification/rso.vue'
registerVuePage(PageCertRSO);
import * as PageCertBuy from './certification/buy-certificate.vue'
registerVuePage(PageCertBuy);
import './certification/buy-certificate-result.js'



import * as PageMAPLevel1 from './map/level1.vue'
registerVuePage(PageMAPLevel1);
import * as PageMAPLevel2 from './map/level2.vue'
registerVuePage(PageMAPLevel2);
import * as PageMAPLevel3 from './map/level3.vue'
registerVuePage(PageMAPLevel3);


import * as PageFlightsRockets from './flights/rockets.vue'
registerVuePage(PageFlightsRockets);
import * as PageFlightsRocketsAdd from './flights/rocket-add.vue'
registerVuePage(PageFlightsRocketsAdd);
import * as PageFlightsRocketsView from './flights/rocket-view.vue'
registerVuePage(PageFlightsRocketsView);
import * as PageFlightsRocketsEdit from './flights/rocket-edit.vue'
registerVuePage(PageFlightsRocketsEdit);
import * as PageFlightsAdd from './flights/flight-add.vue'
registerVuePage(PageFlightsAdd);
import * as PageFlightsEdit from './flights/flight-edit.vue'
registerVuePage(PageFlightsEdit);
import * as PageFlightsSetOutcome from './flights/flight-set-outcome.vue'
registerVuePage(PageFlightsSetOutcome);


import * as PageTools from './tools/tools.vue'
registerVuePage(PageTools);
import * as PageToolsVentHoles from './tools/vent-holes.vue'
registerVuePage(PageToolsVentHoles);
import * as PageToolsEjectionCharge from './tools/ejection-charge.vue'
registerVuePage(PageToolsEjectionCharge);


import * as PageAdmin from './admin/admin.vue'
registerVuePage(PageAdmin);
import * as PageAdminViewUser from './admin/user-view.vue'
registerVuePage(PageAdminViewUser);
import * as PageAdmiEditUser from './admin/user-edit.vue'
registerVuePage(PageAdmiEditUser);
import * as PageAdmiUserHistory from './admin/user-history.vue'
registerVuePage(PageAdmiUserHistory);
import * as PageAdminApprovalsView from './admin/attempt-view.vue'
registerVuePage(PageAdminApprovalsView);
import * as PageAdminMAPApprovalsView from './admin/map-attempt-view.vue'
registerVuePage(PageAdminMAPApprovalsView);
import * as PageAdminEmail from './admin/email.vue'
registerVuePage(PageAdminEmail);
import * as PageAdminImport from './admin/import.vue'
import { connectFormComponents } from '../js/utils/forms.js';
registerVuePage(PageAdminImport);

function registerVuePage(page)
{
	pageManager.registerPage(page.pageName, page.pageRoute, class extends Page {

		static fetchHTML = false
		static requireAuth = page.requireAuth !== false;
		static requireAdmin = page.requireAdmin === true;
		static requireMembership = page.requireMembership === true;
		static requireCompleteAccount = page.requireCompleteAccount === true;
		static requireEmailVerified = page.requireEmailVerified !== false;
		static furniture = page.furniture || 'full';
		static cacheMarkupBy = 'path'

		get title() {
			if(this.app._instance.ctx.title)
				return this.app._instance.ctx.title + ' | UKRA';
			if(page.title)
				return page.title + ' | UKRA';
			
			return 'UKRA';
		}

		beforeHide() {
			if(this.app._instance.ctx.beforeHide)
				return this.app._instance.ctx.beforeHide();
			return undefined;
		}

		async boot(opts) {
			let p = page.default;
			let $page = this.$page
			p.updated = function() {
				connectFormComponents($page)
			};

			this.app = createApp(p, { 
				options: opts
			});
			this.app.mount(this.$page)	
			
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.boot)
				await Promise.resolve(this.app._instance.ctx.boot(opts));
		}

		async update(opts)
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			// TODO: dont call show, can we have a new method or always call update (on show)
			if(this.app._instance.ctx.show)
				await Promise.resolve(this.app._instance.ctx.show(opts));
			await super.update(opts);
		}

		async show(opts)
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.show)
				await Promise.resolve(this.app._instance.ctx.show(opts));
			await super.show(opts);
		}

		async hide()
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.hide)
				await Promise.resolve(this.app._instance.ctx.hide());
			await super.hide();
		}
	})
}