var $toastTemplate = null;
var $toaster = null;
var slices = {};

function removeToast(t) {
	if (t.removeTimeout)
		window.clearTimeout(t.removeTimeout);

	t.$toast.classList.remove('fadeIn');

	t.removeTimeout = setTimeout(e => {
		t.$toast.remove();
	}, 250);

	delete slices[t.sliceId];
}



/// message
/// style
/// buttons: []
///		text
///		action()
export function showToast(opts){
	if(!$toastTemplate){
		$toastTemplate = document.$('.js-template-toast');
		$toastTemplate.remove();
		$toaster = document.$('.js-toaster');
	}

	var $toast = $toastTemplate.cloneNode(true);
	var sliceId = (new Date()).getTime();
	var toast = {
		id: sliceId,
		removeTimeout: null,
		$toast: $toast
	};
	slices[sliceId] = toast;


	$toast.$('.js-message').textContent = opts.message;
	$toast.classList.add(opts.style);

	$toast.$('.js-close').addClickListener(e => {
		e.preventDefault();
		removeToast(toast);
	});

	var $action = $toast.$('.js-action');
	
	if(!opts.buttons || opts.buttons.length == 0)
		$action.remove();
	else {
		var $button = $action.$('button');
		$button.textContent = opts.buttons[0].text;
		$button.addClickListener(e => {
			e.preventDefault();
			removeToast(toast);
			opts.buttons[0].action();
		});
	}

	

	$toaster.appendChild($toast);
	$toast.offsetWidth;
	$toast.classList.add('fadeIn');

	function startTimer()
	{
		toast.removeTimeout && window.clearTimeout(toast.removeTimeout);
		toast.removeTimeout = setTimeout(e => {
			removeToast(toast);
		}, 4000);
	}

	$toast.addEventListener('mouseenter', e => { 
		toast.removeTimeout && window.clearTimeout(toast.removeTimeout);
	});

	$toast.addEventListener('mouseleave', e => {
		if(e.target == $toast)
			startTimer()
	});

	startTimer();
}

export function clearToast(){
	Object.keys(slices).forEach(s =>{
		removeToast(slices[s]);
	});
}
