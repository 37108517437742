export default class RocketView
{
	id = null
	ownerId = null
	createdOn = null
	name = null
	buildType = null
	diameter = null
	length = null
	cp = null
	mass = null
	notes = null

	flights = []

	getFlight(flightId)
	{
		return this.flights.find(f => f.id == flightId)
	}

	deleteFlight(flightId)
	{
		this.flights.splice(this.flights.findIndex(f => f.id == flightId), 1)
	}

	static Flight = class {
		id = null

		name = null
		flownOn = null
		location = null
		latitude = null
		longitude = null
		motors = null
		delay = null
		mass = null
		sm = null
		notes = null

		outcome = null
		landingLatitude = null
		landingLongitude = null
	}
}