import {registerClassForPersistence} from '../lib/automapper.js'

import User from './users/user.js'
import UserView from './users/userView.js';
import UserLite from './users/userLite.js';
import PendingCertificationAttempt from './users/pendingCertificationAttempt.js';
import PendingMAPAttempt from './users/pendingMAPAttempt.js';
import MembershipNumber from './users/membershipNumber.js';
import Rocket from './rockets/rocket.js';
import RocketView from './rockets/rocketView.js';

registerClassForPersistence(User);
registerClassForPersistence(UserView);
registerClassForPersistence(UserLite);

registerClassForPersistence(PendingCertificationAttempt);
registerClassForPersistence(PendingMAPAttempt);

registerClassForPersistence(MembershipNumber);

registerClassForPersistence(Rocket);
registerClassForPersistence(RocketView);