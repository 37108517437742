<script>

export const requireAuth = false;
export const pageName = 'account-set-password';
export const pageRoute = '/account/set-password';
export const title = 'Set password'

import { getAuth, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth'
import { validation } from '../../js/language.js'
import { navigate, PageShowError } from '@trullock/page-manager';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { userChanged } from '../../js/auth.js';
import { countPasswordBreaches } from '../../js/lib/hibp.js';
import { functions } from '../../js/lib/functions.js';

export default {
	data() {
		return {
			newPassword: '',
			email: null
		 }
	},
	methods: {
		submit: async function() {
			showLoading();			
			
			let breaches = await countPasswordBreaches(this.newPassword)
			if(breaches > 0)
			{
				this.$refs.password.setCustomValidity(validation.messages.breached(breaches));
				this.$refs.password.reportValidity();
				hideLoading();
				return;
			}

			try
			{
				await functions.userCompleteResetPassword({
					oobCode: this.oobCode,
					newPassword: this.newPassword
				});
				
				// filter null because initially it "changes" to null as the listener starts looking for the new user that doesnt exist yet
				let userChangedP = userChanged(u => !!u);

				await signInWithEmailAndPassword(getAuth(), this.email, this.newPassword);

				await userChangedP;

				showToast({
					style: 'bg-success',
					message: 'Password changed'
				});

				navigate('goal', { fallback: this.goalUrl });
			}
			catch(error)
			{
				if (error.code == 'auth/weak-password')
				{
					this.$refs.password.setCustomValidity(validation[error.code] || validation['error']);
					this.$refs.password.reportValidity();
				}
				else
				{
					console.error(error);
					//pageManager.show(pageManager.getPath('account-complete-reset-password-error'));
				}
			}	

			hideLoading();
		},
		show: async function(opts) {
			if(!opts.oobCode)
			{
				showToast({
					message: 'Error changing password, invalid URL. Try copying the link from the latest email instead of clicking it',
					style: 'bg-danger'
				})

				throw new PageShowError('/', "Missing oobCode");
			}

			this.oobCode = opts.oobCode;

			if(opts.continueUrl){
				// firebase should enforce same-origin, but we should also check
				var schemeAndHost = window.location.protocol + '//' + window.location.host;
				if(opts.continueUrl.substr(0, schemeAndHost.length).toLowerCase() === schemeAndHost.toLowerCase())
					this.goalUrl = opts.continueUrl.substr(schemeAndHost.length);
			}
			
			try
			{
				this.email = await verifyPasswordResetCode(getAuth(), this.oobCode)
			}
			catch(e)
			{
				showToast({
					message: 'Error changing password, invalid URL. Try copying the link from the latest email instead of clicking it',
					style: 'bg-danger'
				})

				throw new PageShowError('/', 'Error verifying password reset code. ' + e);
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-5 col-xl-4 my-5">
			<h1 class="display-4 text-center mb-3">Set password</h1>
			<p class="text-muted text-center mb-5">Choose a new password.</p>
			<form @submit.prevent="submit">
				<fieldset class="mb-4">
					<legend>Account details</legend>
					<div class="form-group mb-4">
						<label>New password</label>
						<div class="input-group has-validation">
							<input type="password" class="form-control" ref="password" v-model="newPassword" autocomplete="new-password" placeholder="e.g. Something long and unique" required />
							<div class="input-group-append">
								<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
							</div>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text small text-muted mt-2 mb-0">8 characters or more, must not be a <a href="/account/password-strength" target="_blank">common password <span class="fe fe-external-link"></span></a>.</span>
					</div>
				</fieldset>
				<button type="submit" class="btn btn-lg btn-block btn-primary mb-3">Set password <span class="fe fe-check-circle"></span></button>
			</form>
		</div>
	</div>
</div>
</template>