<script>

export const requireAuth = false;
export const pageName = 'account-reset-password';
export const pageRoute = '/account/reset-password';
export const title = 'Reset password'

import { getAuth } from 'firebase/auth'
import { validation } from '../../js/language.js'
import { navigate, PageShowError } from '@trullock/page-manager';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { functions } from '../../js/lib/functions.js';

export default {
	data() {
		return {
			email: ''
		 }
	},
	methods: {
		submit: async function() {

			showLoading();
						
			let result = await functions.userRequestResetPassword({ email: this.email })
			if(!result.success)
			{
				showToast({ message: 'Error resetting your password', style: 'bg-danger'})
				hideLoading();
				return;
			}

			showToast({
				message: 'Reset password email sent, check your inbox',
				style: 'bg-success'
			})
			
			navigate('/')

			hideLoading();
		},
		show: function(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')

			functions.warmUp.userRequestResetPassword();
			
			this.email = opts.email || '';
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Reset password</h1>
			<p class="text-muted text-center mb-5">Let's get you signed in.</p>
			<form @submit.prevent="submit">
				<fieldset class="mb-4">
					<legend>Account details</legend>
					<div class="form-group mb-4">
						<label>Email</label>
						<input type="email" class="form-control" name="email" v-model="email" autocomplete="email" placeholder="e.g. your.name@example.com" required tabindex="1" />
						<span class="invalid-feedback"></span>
					</div>
				</fieldset>
				<button type="submit" class="btn btn-lg btn-block btn-primary mb-3">Send Email</button>
				<div class="text-center">
					<p class="small text-muted mb-2">Remembered your details? <a href="/account/sign-in">Sign in</a>.</p>
                	<p class="small text-muted">Not got an account yet? <a href="/account/sign-up">Sign up</a> now.</p>
				</div>
			</form>
		</div>
	</div>
</div>
</template>