import { attemptTypeToLevel } from "../certification-utils.js"

export default class PendingCertificationAttempt
{
	id = null
	userId = null
	userName = null
	type = null
	submittedOn = null

	get level()
	{
		return attemptTypeToLevel(this.type);
	}

	get flight()
	{
		switch(this.type)
		{
			case 'L1S1':
				return 'Flight 1';
			case 'L1S2':
				return 'Flight 2';
			case 'L1S3':
				return 'Flight 3';
			case 'L2S1':
				return 'Flight 1';
			case 'L2S2':
				return 'Flight 2';
			case 'L3S1':
				return 'Flight 1';
			default:
				return undefined;
		}
	}
}