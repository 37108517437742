<script>

export const pageName = 'account-sar';
export const pageRoute = '/account/subject-access-request';
export const title = 'Subject Access Request'
export const requireEmailVerified = false;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { getUserEvents, getUserRocketEvents } from '../../js/readModel.js';
import { getCurrentUserId } from '../../js/auth.js';

export default {
	data() {
		return {
		}
	},
	methods: {
		download: async function() {

			showLoading();			
			
			let events = await Promise.all([getUserEvents(getCurrentUserId()), getUserRocketEvents(getCurrentUserId())]);
			events = events[0].concat(events[1]);
			events.sort((a,b) => a.occurredOn - b.occurredOn)

			let content = events.map(event => {
				return `event type: ${event.type}
occurred: ${event.occurredOn.toString()}
event data: ${JSON.stringify(event.data)}`;
			}).join('\n----------\n')

			var blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  			var url = URL.createObjectURL(blob);

			window.open(url);

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Subject Access Request</h1>
			<p class="text-muted text-center mb-5">Download your data.</p>

			<p>Under GDPR you are entitled to download all the data we hold on you.</p>
			<button @click.prevent="download" class="btn btn-primary btn-block">Download data <span class="fe fe-download"></span></button>
		</div>
	</div>
</div>
</template>