<script>
export const pageName = 'account-forbidden';
export const pageRoute = '/account/forbidden';
export const title = 'Access Denied';
export const requireEmailVerified = false;

export default {
	data() {
		return { 
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-5 col-xl-4 my-5">
			<h1 class="display-4 text-center mb-3">Forbidden</h1>
			<p class="text-muted text-center mb-5">You do not have permission to access this page</p>
			<div class="text-center mb-2">
				<a href="/" class="btn btn-primary">Go home <span class="fe fe-arrow-right-circle"></span></a>
			</div>
        </div>
    </div>
</div>
</template>