<script>
import { getCurrentUserId } from '../../js/auth.js';
import { getUserView } from '../../js/readModel.js';
import { functions } from '../../js/lib/functions.js'
import { getAuth, signOut } from '@firebase/auth';
import { showConfirm } from '../../js/utils/confirm.js';

export const pageName = 'account-close';
export const pageRoute = '/account/close';
export const title = 'Close account'
export const requireEmailVerified = false;

export default {
	data() {
		return { 
			user: null,
			ready: false
		}
	},
	methods: {
		async deleteAccount() {
			functions.warmUp.userDelete()
			
			showConfirm({
				title: 'Confirm account closure',
				message: `Are you sure you want to close your account?<br/>In accordance with our privacy policy and terms of service all data identifying you will be erased.`,
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Close account',
						style: 'danger',
						action: async () => {
							let result = await functions.userDelete();
							if(!result.success)
							{
								showToast({ message: 'Error changing your name', style: 'bg-danger'})
								return;
							}
							
							await signOut(getAuth())

							// hard navigation to tear down JS envrionment
							window.location = '/';
						}
					}
				]
			})
		},
		async show(opts)
		{
			this.user = await getUserView(getCurrentUserId());
			this.ready = true;
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
	<div class="row justify-content-center">
		<div class="col-12 col-md-5 col-xl-4 my-5">
			<h1 class="display-4 text-center mb-3">Close account</h1>
			<p class="text-muted text-center mb-5">Closing your account will remove your access to UKRA and end your UKRA membership.</p>
			<form ref="form" @submit.prevent="deleteAccount">
				<button type="submit" class="btn btn-block btn-outline-danger">Close account <span class="fe fe-trash-2"></span></button>
			</form>
		</div>
	</div>
</div>
</template>