<script>

export const requireAuth = false;
export const pageName = 'privacy-policy';
export const pageRoute = '/privacy-policy';
export const title = 'Privacy Policy'

export default {
	data() { return { } },
}
</script>
<template>
	<div class="container">

		<div class="row">
			<div class="col">
				<div class="header mb-5">
					<div class="header-body">
						<h6 class="header-pretitle">
							Updated 23rd July 2024
						</h6>
						<h1 class="header-title">
							Privacy policy
						</h1>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md">
				<p class="">
					We want you to know that your privacy is really important to us and you can trust that we’ll always protect and safeguard the personal data that you give us. This policy clearly explains who we are, how we collect/use personal data and how you can exercise your privacy rights. Questions? Contact us, we’re happy to help.
				</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-8">
				<h2>Policy details</h2>
				<p>At the United Kingdom Rocketry Association hereafter referred to as “UKRA”, we're committed to protecting and respecting your privacy.</p>
				
				<p>This policy explains when and why we collect personal information about people who join UKRA by whatever means, how we use it, the conditions under which we may disclose it to others and your rights in relation to your personal data.</p>
				<p>We may change this notice from time to time to ensure that you're happy with any changes. By using the UKRA Members Portal, you're agreeing to be bound by this notice.</p>
				
				<h2>How do we collection information about you?</h2>
				<p>We obtain information about you directly from you with consent when you sign up to, and begin using the UKRA Members Portal. We may also communicate with the BMFA to prove the validity of your BMFA insurance. No personal information is communicated in either direction. We provide the BMFA with your BMFA number and they reply with a yes/no answer representing insurance validity.</p>
				
				<h2>What type of information is collected about you?</h2>
				<p>The personal information we collect for the purposes of <em>providing services, and goods, including delivery</em> and <em>the operation of customer accounts and guarantees</em>:</p>
				<ul>
					<li>Names and contact details</li>
					<li>Date of birth</li>
					<li>Purchase or account history</li>
					<li>Account information</li>
					<li>Website user information (including user journeys and cookie tracking)</li>
					<li>Photographs or video recordings</li>
					<li>Information used for security purposes</li>
				</ul>
				<p>The lawful basis for the processing of your personal data for the purposes of <em>providing services, and goods, including delivery</em> and <em>the operation of customer accounts and guarantees</em> is via consent during the Members Portal signup process. For legacy accounts held in any of our previous membership systems, consent was given at the time of UKRA membership and/or certification application.</p>

				<h2>How is your information used?</h2>
				<p>We may use your information to:</p>
				<ul>
					<li>process your membership</li>
					<li>to carry out our obligations arising from your membership</li>
					<li>seek your views or comments on matters relating to UKRA and rocketry</li>
					<li>notify you of changes to our services</li>
					<li>send you communications which you have requested and that may be of interest to you. These may include information about events and other rocketry related matters, also information from the BMFA that may be of interest.</li>
				</ul>

				<h2>How long do we retain your information?</h2>
				<p>We will hold your personal information on our systems for as long as is necessary to carry out our obligations in relation to your membership, or as long as is set out in any relevant agreement between us.</p>
				<p>Currently we will purge your personal information after 5 years of membership inactivity.</p>

				<h2>Who has access to your information?</h2>
				<p>We will not sell or rent your information to third parties.</p>
				<p>We will not share your information with third parties for marketing purposes.</p>
				<p>We may share your BMFA Number only with the BMFA in order to validate your BMFA insurance. Please see the BMFA privacy policy at <a href="https://www.bmfa.org/Privacy-Policy" target="_blank">https://www.bmfa.org/Privacy-Policy</a>.</p>
				<p>Please be reassured that we will not release your information to third parties beyond UKRA unless we are required to do so by law, for example, by a court order or for the purposes of prevention of fraud or other crime.</p>

				<h3>Data processors</h3>
				<h4>Google</h4>
				<p>This data processor hosts our members' database and processes member information in order to provide our service.</p>
				<h4>Ivelop Consulting Ltd.</h4>
				<p>This data processor provides technical support for our membership platform in order for us to provide our service.</p>
				<h3>Others we share personal information with</h3>
				<ul>
					<li>Organisations we need to share information with for safeguarding reasons</li>
					<li>Relevant regulatory authorities</li>
					<li>Organisations we're legally obliged to share personal information with</li>
					<li>Emergency services (where necessary)</li>
				</ul>
				<h2>Sharing information outside the UK</h2>
				<p>Where necessary, our data processors may share personal information outside of the UK. When doing so, they comply with the UK GDPR, making sure appropriate safeguards are in place.</p>
				<h2>How you can access and update your information?</h2>
				<p>The accuracy of your information is important to us. Please update your persoanl information via the Members Portal whenever it changes.</p>
				<h2>Your data protection rights</h2>
				<p>Under data protection law, you have rights including:</p>
				<p>Your right of access - You have the right to ask us for copies of your personal data.</p>
				<p>Your right to rectification - You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
				<p>Your right to erasure - You have the right to ask us to erase your personal data in certain circumstances.</p>
				<p>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal data in certain circumstances.</p>
				<p>Your right to object to processing - You have the right to object to the processing of your personal data in certain circumstances.</p>
				<p>Your right to data portability - You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.</p>
				<p>Your right to withdraw consent - When we use consent as our lawful basis you have the right to withdraw your consent.</p>
				<p>You don't usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you.</p>
				<p>To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.</p>

				<h2>How to complain</h2>
				<p>If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.</p>
				<p>If you remain unhappy with how we've used your data after raising a complaint with us, you can also complain to the ICO.</p>
				<p>The ICO's address:</p>
				<address>Information Commissioner's Office
Wycliffe House,
Water Lane,
Wilmslow,
Cheshire,
SK9 5AF</address>
				<p>Helpline number: <a href="tel:0303 123 1113">0303 123 1113</a></p>
				<p>Website: <a href="https://www.ico.org.uk/make-a-complaint" target="_blank">https://www.ico.org.uk/make-a-complaint</a></p>
			</div>
			<div class="col-12 col-md-4">
				<div class="card shadow-light-lg">
					<div class="card-body">
						<h4>
							Have a question?
						</h4>
						<p class="font-size-sm text-gray-800 mb-5">
							Not sure exactly what we're looking for or just want clarification? We'd be happy to chat with you and clear things up for you.
						</p>
						<h6 class="font-weight-bold text-uppercase text-gray-700 mb-2">
							Email us
						</h6>
						<p class="font-size-sm mb-0">
							<a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>