<script>
import { getCurrentUserId } from '../../js/auth.js';
import { getUserView } from '../../js/readModel.js';
import { functions } from '../../js/lib/functions.js'
import { showToast } from '../../js/utils/toast.js'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { PageShowError, navigate } from '@trullock/page-manager'
import UserView from '../../../functions/domain/users/userView.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';

export const pageName = 'account-email-verify';
export const pageRoute = '/account/email-verify';
export const title = 'Verify account'
export const requireEmailVerified = false;

export default {
	data() {
		return { 
			currentUser: null,
			ready: false
		}
	},
	methods: {
		async resendEmail() {
			showLoading();

			let result = await functions.userRequestEmailVerify();
			if(!result.success)
			{
				showToast({ message: 'Error requesting email verification', style: 'bg-danger'})
				hideLoading();
				return;
			}

			showToast({
				message: 'Check your inbox',
				style: 'bg-success'
			})

			hideLoading();
		},
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], (user, firstCall) => {
				this.currentUser = user;
				// TODO: expose this.visible and other page base stuff
				// if(!firstCall && this.visible && this.currentUser.emailVerified)
				// 	navigate('/');
			});

			this.ready = true;
		},
		async show(opts)
		{
			functions.warmUp.userRequestEmailVerify();

			if(this.currentUser.emailVerified)
				throw new PageShowError('/', 'Already email verified', {}, 'replace');

			this.ready = true;
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
	<div class="row justify-content-center">
		<div class="col-12 col-md-6 my-5">
			<h1 class="display-4 text-center mb-3">Verify your email</h1>
			<p class="text-muted text-center mb-5">Before you continue we need to prove you have access to {{  currentUser.email }}. Check your inbox for an email from UKRA or click the button below to resend it.</p>
			<form ref="form" @submit.prevent="resendEmail">
				<button class="btn btn-block btn-outline-primary" type="submit">Resend email <span class="fe fe-send"></span></button>
			</form>
		</div>
	</div>
</div>
</template>