<script>
export const pageName = 'tools';
export const pageRoute = '/tools';
export const title = 'Tools'

export default {
	data() {
		return { 
		}
	},

	methods: {
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Tools</h1>
				<p class="text-muted text-center mb-5">What are you stuck with?</p>
			</div>
			<div class="col-12 col-md-10">
				<h2>Calculators</h2>
				<div class="row">
					<div class="col-4">
						<div class="card">
							<div class="card-header">
								<h4 class="card-header-title">Barometer vent holes</h4>
							</div>
							<div class="card-body">
								<p>Calculate the size and number of required vent holes for barometer based flight computers.</p>
								<a href="/tools/vent-holes" class="btn btn-primary btn-block">Vent holes <span class="fe fe-arrow-right-circle"></span></a>
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card">
							<div class="card-header">
								<h4 class="card-header-title">Ejection charge</h4>
							</div>
							<div class="card-body">
								<p>Calculate the amount of FFFFg black powder to use with ejection charges.</p>
								<a href="/tools/ejection-charge" class="btn btn-primary btn-block">Ejection charge <span class="fe fe-arrow-right-circle"></span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
