export function attemptTypeToLevel(type)
{
	switch(type)
	{
		case 'L1S1':
		case 'L1S2':
		case 'L1S3':
			return 'Level 1';
		case 'L2S1':
		case 'L2S2':
			return 'Level 2';
		case 'L3S1':
			return 'Level 3';
		case 'MAP1S1':
			return 'MAP Level 1';
		case 'MAP2S1':
		case 'MAP2S2':
		case 'MAP2SX':
			return 'MAP Level 2';
		case 'MAP3S1':
		case 'MAP3S2':
		case 'MAP3S3':
		case 'MAP3SX':
			return 'MAP Level 3';
		case 'RSO':
			return 'RSO';
		default:
			return type;
	}
}

export function certLevelToLevel(level)
{
	switch(level)
	{
		case 'level1':
			return 'Level 1'
		case 'level2':
			return 'Level 2'
		case 'level3':
			return 'Level 3'
		default:
			return 'None';
	}
}

export function rsoLevelToLevel(level)
{
	switch(level)
	{
		case 'model':
			return 'Model'
		case 'level1':
			return 'Level 1'
		case 'level2':
			return 'Level 2'
		case 'level3':
			return 'Level 3'
		default:
			return 'None';
	}
}