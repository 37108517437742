import { attemptTypeToLevel } from "../certification-utils.js"

export default class PendingMAPAttempt
{
	id = null
	userId = null
	userName = null
	type = null
	submittedOn = null

	get level()
	{
		return attemptTypeToLevel(this.type);
	}

	get flight()
	{
		switch(this.type)
		{
			case 'MAP1S1':
				return 'Flight 1';
			case 'MAP2S1':
				return 'Flight 1';
			case 'MAP2S2':
				return 'Flight 2';
			case 'MAP2SX':
				return 'Extra Flight';
			case 'MAP3S1':
				return 'Flight 1';
			case 'MAP3S2':
				return 'Flight 2';
			case 'MAP3S3':
				return 'Flight 3';
			case 'MAP3SX':
				return 'Extra Flight';
			default:
				return undefined;
		}
	}
}