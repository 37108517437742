import { stripeKey } from '../../config.js'

let stripe = null;
export function loadStripe() {
	if (window.Stripe)
		return Promise.resolve(stripe);

	return new Promise(resolve => {
		var script = document.createElement('script');
		script.onload = function () {
			stripe = Stripe(stripeKey)
			resolve(stripe);
		};
		script.src = 'https://js.stripe.com/v3/';
		document.head.appendChild(script);
	});
}
