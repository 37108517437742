<script>
export const pageName = 'admin-approvals-view';
export const pageRoute = '/admin/user/{userId}/attempt/{attemptId}';
export const title = 'View Attempt'
export const requireAdmin = true;

import { functions } from '../../js/lib/functions.js';
import { navigate, PageShowError } from '@trullock/page-manager'
import { showToast } from '../../js/utils/toast.js';
import UserView from '../../../functions/domain/users/userView.js';
import { showConfirm } from '../../js/utils/confirm.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js';

export default {
	data() {
		return { 
			user: null,
			rawAttempt: null,
			reason: '',
			attempt: null,
			ready: false
		}
	},

	methods: {
		rsoLevelToLevel,
		async boot(opts) {
			await listenToProjection([UserView, opts.userId], user => {
				this.user = user;
				this.attempt = user.certification.getAttempt(opts.attemptId);
			});
			
			this.ready = true;
		},
		async show(opts)
		{
			if(this.attempt.result != null)
				throw new PageShowError('/admin', 'Attempt has already been processed', null, 'replace')
		},
		async approve()
		{
			showConfirm({
				title: 'Confirm approval',
				message: 'Are you sure you want to approve this attempt?' + (this.reason?.length ? '<br><br>The user will be given your message: "' + this.reason + '"' : ''),
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Approve',
						style: 'success',
						action: async () => {
							let result = await functions.userCertificationAttemptApprove({
								userId: this.user.id,
								attemptId: this.attempt.id,
								reason: this.reason
							});

							if(!result.success)
							{
								showToast({
									message: 'Error: ' + result.error,
									style: 'bg-danger'
								});

								return;
							}

							navigate('/admin')

							showToast({
								message: 'Certification attempt approved',
								style: 'bg-success'
							})
						}
					}
				]
			});
			
		},
		async reject()
		{
			if(this.reason?.length == 0)
			{
				this.$refs.reason.setCustomValidity('Required')
				this.$refs.reason.reportValidity();
				return;
			}	
			else
				this.$refs.reason.setCustomValidity('')
			
			showConfirm({
				title: 'Confirm rejection',
				message: 'Are you sure you want to reject this attempt?<br><br>The user will be given your message: "' + this.reason + '"',
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Reject',
						style: 'danger',
						action: async () => {
							let result = await functions.userCertificationAttemptReject({
								userId: this.user.id,
								attemptId: this.attempt.id,
								reason: this.reason
							});

							if(!result.success)
							{		
								showToast({
									message: 'Error: ' + result.error,
									style: 'bg-danger'
								});

								return;
							}
							
							navigate('/admin/user/' + this.user.id)

							showToast({
								message: 'Certification attempt rejected',
								style: 'bg-success'
							})
						}
					}
				]
			});
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-body">

						<a :href="`/admin/user/${user.id}`" class="avatar avatar-xl card-avatar">
							<img :src="user.avatar" class="avatar-img rounded-circle border border-4 border-card" alt="...">
						</a>

						<h2 class="card-title text-center">
							<a :href="`/admin/user/${user.id}`">{{ user.name }}</a>
						</h2>

						<p class="text-center mb-4">
							<span :class="`badge mx-2 ${user.certification.level1.status == 'passed' ? 'badge-success' : (user.certification.level1.status == 'in-progress' ? 'badge-secondary' : 'badge-light') }`">Level 1</span>
							<span :class="`badge mx-2 ${user.certification.level2.status == 'passed' ? 'badge-success' : (user.certification.level2.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 2</span>
							<span :class="`badge mx-2 ${user.certification.level3.status == 'passed' ? 'badge-success' : (user.certification.level3.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 3</span>
							<span v-if="user.rso.level" class="badge mx-2 badge-success">{{ rsoLevelToLevel(user.rso.level) }} RSO</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Attempt details</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush mb-4">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Flight date
									</div>
									<div class="col-auto">
										{{  attempt.flownOn.format('yyyy/MM/dd') }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Flight location
									</div>
									<div class="col-auto">
										{{  attempt.location }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket Name
									</div>
									<div class="col-auto">
										{{  attempt.name }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket Diameter
									</div>
									<div class="col-auto">
										{{  attempt.diameter }}mm
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket Length
									</div>
									<div class="col-auto">
										{{  attempt.length }}mm
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket CG
									</div>
									<div class="col-auto">
										{{  attempt.cg }}mm
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket CP
									</div>
									<div class="col-auto">
										{{  attempt.cp }}mm
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Rocket Stability Margin
									</div>
									<div class="col-auto">
										{{  attempt.sm }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Predicted Alt
									</div>
									<div class="col-auto">
										{{  attempt.predictedAlt }}ft
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Motor Manufacturer(s)
									</div>
									<div class="col-auto">
										{{  attempt.motorManufacturers }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Motor Designation(s)
									</div>
									<div class="col-auto">
										{{  attempt.motorDesignations }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Build Type
									</div>
									<div class="col-auto">
										{{  attempt.type }}
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										Comments
									</div>
									<div class="col-auto">
										{{  attempt.comments }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col col-lg-8">
				<div class="card">
					<div class="card-body img-container">
						<a :href="attempt.flightCardUrl" target="_blank"><img :src="attempt.flightCardUrl" /></a>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<form>
					<div class="form-group mb-4">
						<textarea v-model="reason" required ref="reason" placeholder="Notes for the member" rows="5" class="form-control"></textarea>
						<span class="invalid-feedback"></span>
					</div>
					<div class="row">
						<div class="col">
						</div>
						<div class="col-auto">
							<button type="submit" @click.prevent="approve" class="btn btn-success">Approve <span class="fe fe-check-circle"></span></button>
						</div>
						<div class="col-auto">
							<button type="submit" @click.prevent="reject" class="btn btn-danger">Reject <span class="fe fe-x-circle"></span></button>
						</div>
					</div>
				</form>
			</div>
		</div>
    </div>
</template>
