<script>

export const requireAuth = false;
export const pageName = 'terms-of-service';
export const pageRoute = '/terms-of-service';
export const title = 'Terms of service'

export default {
	data() {
		return { }
	},
	methods: {
		show(opts) {
			
		}
	}
}

</script>
<template>
	<div class="container">
		
		<div class="row">
			<div class="col">
				<div class="header mb-5">
					<div class="header-body">
						<h6 class="header-pretitle">
							Updated 23rd July 2024
						</h6>
						<h1 class="header-title">
							Terms of service
						</h1>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-8">
				<p>Note: Prior to Using the Service, should you require a variation of these terms or help understanding them, then write to our team by emailing <a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a>. By Using the Service you agree to be bound by them and further agree that you had the opportunity to negotiate these Terms of Use fairly prior to Using the Service.</p>
				
				<p>The UKRA Members Portal is operated by United Kingdom Rocketry Association (UKRA) which has its registered office address at 8 Merus Court, Meridian Business Park, Leicester, LE19 1RJ (referred to as "UKRA", “we”, “our” or “us”).</p>
				<p>Use of the Website is governed by these terms and conditions of use (the "Terms").</p>
				<p>Please see our Privacy to understand how and why we collect your personal data, with whom we share it and the rights and choices you have over that data.</p>
				<h2>1. Acknowledgement and acceptance</h2>
				<p>1.1 By agreeing to this policy during sign-up or by simply accessing and using the UKRA website, you agree to be bound by these Terms. Should you not wish to accept these Terms, you must exit the UKRA at once and/or or cease participation.</p>
				<p>1.2 UKRA reserves the right from time to time, to modify or amend these Terms or any part of them. Where possible, UKRA will give you notice by email and where possible, you will be informed in advance of any changes in good time before they become effective. Your continued use of UKRA once the changes take effect shall constitute an affirmative acknowledgement of any modification and your continued agreement to be bound by the modified Terms.</p>
				<h2>2. User Content</h2>
				<p>2.1 Use of UKRA Members Portal involves you submitting personal and flight information and other content to us ("User Content").</p>
				<p>2.2 You acknowledge that User Content provided by you is your sole responsibility. This means that you, and not UKRA, are entirely responsible for all User Content you upload, post, link to, email or otherwise transmit via your use of UKRA Members Portal.</p>
				<p>2.3 You acknowledge that User Content provided by you will be visible to UKRA and website administrators.</p>
				<p>2.4 With respect to all User Content you elect to transmit to us, you grant UKRA a royalty-free, perpetual, irrevocable, non-exclusive licence (with the right to sublicense) to use, reproduce, modify, adapt, edit, publish, translate, create derivative works from, exploit, perform and display such User Content (in whole or part) throughout the world and/or to incorporate it in other works in any form, media or technology now known or later developed, for any purposes.</p>
				<p>2.5 In the course of using UKRA Members Portal, you agree not to:</p>
				<p>2.5.1 Upload, post, link to, email or otherwise transmit any information that is unlawful in any way in the United Kingdom, or in the country in which you reside;</p>
				<p>2.5.2 Upload, post, link to, email or otherwise transmit any information that is abusive, defamatory, threatening, harassing, obscene, discriminatory, likely to cause distress, intended to incite hatred or is otherwise objectionable as determined by us in our sole discretion;</p>
				<p>2.5.3 Impersonate any person (whether living or dead) or entity;</p>
				<p>2.5.4 Manipulate identities in order to disguise the origin of any content transmitted through UKRA Members Portal;</p>
				<p>2.5.5 Upload, post, link to, or otherwise transmit any information that you do not have a right to transmit;</p>
				<p>2.5.6 Upload, post, link to, email or otherwise transmit any unsolicited or unauthorised advertising, promotional materials, "junk mail", "chain letters", "phishing emails", "pyramid schemes", or any other form of solicitation;</p>
				<p>2.5.7 Upload, post, link to, email or otherwise transmit any material that contains software viruses or any other computer files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
				<p>2.5.8 Collect or store personal data about other individuals</p>
				<h2>3. Terminating your membership</h2>
				<p>3.1. You agree that UKRA, in its sole discretion, may terminate or suspend your UKRA Members Portal access, or remove or discard any User Content, for any reason, including, without limitation if UKRA believed that you have violated or acted inconsistently with the letter or spirit of these Terms.</p>
				<p>3.2 You agree that UKRA may terminate your access to UKRA Members Portal under any provision of these Terms without notice, and acknowledge and agree that UKRA may immediately bar access to UKRA Members Portal. Further, you agree that UKRA shall not be liable to you or any third-party for any termination of your access to UKRA Members Portal.</p>
				<p>3.3 UKRA reserves the right to terminate UKRA Members Portal at any time. </p>
				<h2>4. Your obligation to reimburse us</h2>
				<p>7.1 You hereby agree to fully reimburse UKRA and its directors, officers, employees and agents from and against any and all liability, damages, losses, claims (including reasonable legal fees) each of them suffers or incurs resulting in any way from your use of UKRA Members Portal, the provision of User Content or resulting from any breach of these Terms.</p>
				<h2>5. Third party websites</h2>
				<p>5.1 Links included within UKRA Members Portal may let you leave UKRA Members Portal and enter into other website(s) ("Linked Site(s)"). The Linked Sites are not under the control of UKRA and UKRA is not responsible and nor shall it be liable for the contents of any Linked Sites or any links contained in a linked site or any changes or updates to such sites. UKRA is not responsible for webcasting or any other form of transmission received from any Linked Site. UKRA is only providing these links to you as a convenience and the inclusion of any link does not imply endorsement by UKRA of the site or any association with their operators.</p>
				<h2>6. Intellectual property</h2>
				<p>6.1 The Website and all pages and content within UKRA Members Portal, including, but not limited to, text, graphics, audio, video, photographs, software, inventions, surveys, logos or other materials (“Materials”) are the intellectual property of, or are authorised for use by, UKRA and its licensors, business partners and affiliates, including all trademarks, service marks, copyrights, patents, database rights and trade secrets contained therein. The compilation, organisation and display of the content as well as all software and inventions used on and in connection with UKRA are the exclusive property of UKRA and its licensors. Except as expressly permitted in these Terms, you may not modify, copy, reproduce, create derivative works, republish, display, upload, post, transmit, distribute or use in any way content available on the Website or App without the prior written consent of UKRA.</p>
				<h2>7. Other important terms</h2>
				<p>7.1 Notices relating to these Terms. All notices given by UKRA will be sent, where possible, to designated email address provided during the UKRA Members Portal sign-up process.</p>
				<p>7.2 If a court finds part of these Terms illegal or otherwise invalid, the rest will continue in force. Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
				<p>7.3 Reliance on these Terms. We intend to rely on these written Terms and any document expressly referred to in them in relation to the subject matter of any agreement between us. We and you will be legally bound by these Terms.</p>
				<p>7.4 Events or circumstances beyond our reasonable control. If we are prevented or delayed from complying with our obligations under these Terms by anything you (or anyone acting on your behalf) does or fails to do or due to events or circumstances beyond our reasonable control, our inability or delay in performing our obligations will not be deemed to be in breach of these Terms. Examples of such events and circumstances include fire, flood and other acts of God, strikes, trade disputes, lock outs, restrictions of imports or exports, riot, accident, disruption to energy supplies, civil commotion, acts of terrorism or war.</p>
				<p>7.5 References to 'including' and other similar expressions. In these Terms, words that appear after the expression 'include', 'including', 'other', 'for example', 'such as' or 'in particular' (or any similar expression) will not limit the meaning of the words appearing before such expression. </p>
				<p>7.6 We may transfer this Agreement to someone else. We may transfer our rights and obligations under these Terms to another organisation. Where possible, we will contact you to let you know if we plan to do this. If you are unhappy with the transfer you should cease using the UKRA WebPages and taking part in UKRA Activities.</p>
				<p>7.7 You need our consent to transfer your rights to someone else. You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</p>
				<p>7.8 Nobody else has any rights under these Terms. The agreement made under these Terms is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the agreement between us or to make any changes to these Terms.</p>
				<p>7.9 Language. These Terms may be presented to you in more than one language. However, the English language version of these Terms shall prevail. Any agreement between us will be concluded in English.</p>
				<p>7.10 Even if we delay in enforcing a Contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaking the Contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
				<p>7.11 The laws that apply to these Terms and where you may bring legal proceedings. These Terms are governed by English law and you can bring legal proceedings in respect of them in the English courts. If you live in Scotland you can bring legal proceedings in respect of them in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of them in either the Northern Irish or the English courts.</p>


				<h1>Terms for Clients</h1>
				<h2>(1) Definitions</h2>
				<p>“Use” or “Using” means your use of this site in any form including but not limited to signing up/registering for any account with UKRA, or by browsing the website https://members.ukra.org.uk.</p>
				<p>“Service” , “Services” or “SERVICES” means any service (or services) which UKRA provides for You.</p>
				<p>“Customer”, "Member" or “You” means you as the individual user of the Service, or if using on behalf of an organisation then “You” shall be taken to mean that particular organisation.</p>
				<p>“Your” means belonging to or associated with You.</p>
				<p>“Terms” or “Terms of Use” means these terms of use.</p>
				<p>“Fees for Services” means the fees which UKRA charges you for your Use of the Service. This is either expressed by a quote rendered by UKRA or by you signing up to a Paid Account with UKRA.</p>
				<p>“Taxes” means any taxes which are due as a result of you using the Service. Please note that UKRA may charge You VAT (if applicable), on any amount you pay for the Service, in accordance to the laws of England & Wales, including the Value Added Tax Act 1994 (as amended).</p>
				<p>"UKRA" or “Us” means United Kingdom Rocketry Association which has its registered office address at United Kingdom Rocketry Association, 8 Merus Court, Meridian Business Park, Leicester, LE19 1RJ. UKRA reserves the right to change its registered office address at any time providing it does not affect the Service.</p>
				
				<p>“Subscription Period” means the period of subscription, beginning from the date You sign up to or upgrade to a Paid Account and will continue until You cancel the renewal in accordance to the conditions laid out in clause 6.2 or 6.3 as applicable to Your payment method or your Subscription automatically expires.</p>
				<p>“Paid Account” means an account which You pay for.</p>
				<p>“Consumer” means an individual acting for purposes which are wholly or mainly outside that individual's trade, business, craft or profession.</p>
				<p>“Intellectual Property Rights” means trademarks, trade names, service marks, domain names, copyrights and all applications and registration of such worldwide, schematics, inventions, know-how, trade secrets, and other intangible proprietary information, whether registered or unregistered, globally.</p>
				<p>“Data Protection Laws” means the Data Protection Act 1998 and all future laws and regulations in the United Kingdom that will come into effect, when they come into effect, including but not limited to the General Data Protection Regulation (REGULATION (EU) 2016/679) or its equivalent United Kingdom legislation.</p>
				<p>“Personal Data” shall have the same meaning as that of the applicable Data Protection Laws.</p>
				<p>“Service IP Rights” means any and all source code, object code and software provided for in the Service by UKRA.</p>
				<p>“Confidential Information” means any data or information that belongs to UKRA and is not generally known to the public, whether in tangible or intangible form, in whatever medium provided, whether unmodified or modified by the User, whenever and however disclosed, including, but not limited to:</p>
				<p>(i) any marketing strategies, plans, prototypes, development plans, test programmes, financial information, projections, operations, sales estimates, business plans, future business plans and performance results relating to the past, present or future business activities;</p>
				<p>(ii) plans for products or services, customer list and supplier lists;</p>
				<p>(iii) any scientific or technical information, invention, design, process, procedure, formula, improvement, technology or method;</p>
				<p>(iv) any concepts, reports, data (unless that data belongs to the User), know-how, works-in-progress, designs, development tools, specifications, computer software, mobile technology software, source code, object code, flow charts, databases, inventions, information and trade secrets;</p>
				<p>(v) any document or communication which is orally said to be “confidential information” or bears a designation of the word/words “Confidential”, “Proprietary” or a similar designation which can reasonably be interpreted to communicate commercially sensitive information;</p>
				<p>(vi) any information generated by the Receiving Party that contains, reflects, or is derived from any of the above mentioned sub clauses under the definition of “Confidential Information” ((i) – (v)).</p>
				<p>(vii) any other information that should reasonably be recognised as confidential information by a reasonably prudent person.</p>
				<p>“Gross Negligence” means failure to use even slight care or conduct that is so careless as to show complete disregard for the rights of others.</p>
				<!-- <p>“Standard Contractual Clauses” means the agreement which can be found on the following link: UKRA_SCC_with_EEA_Customers.pdf</p> -->
				<h2>(2) Acceptance of terms</h2>
				<p>The Services that UKRA may provide to you are subject to the following Terms of Use. Your Use of the Service constitutes acceptance of these Terms of Use.</p>
				<h2>(3) Changes & Updates to Terms of Use</h2>
				<p>UKRA reserves the right to update or change these Terms of Use at any time by giving you a notice by email. UKRA may change or update for a variety of reasons, for example, to comply with legal changes, improve the Service, make changes to the Services or due to legal/compliance considerations and mitigating our risk.</p>
				<h2>(4) Fees</h2>
				<p>4.1 You will pay the Fees for Services of a Paid Account that you sign up to in accordance to the prices advertised on our pricing page.</p>
				<p>4.2 UKRA reserves the right to change the Fees for Services at any time by any amount, however, such changes will not affect the Fees for Services in a Subscription Period for which you have already entered and paid for. If UKRA does alter the Fees for Services, they will not affect You until you enter a new Subscription Period.</p>
				<p>4.3 If and with our agreement, you pay by another other method that is not stipulated under 4.1 (for example by raising a purchase order or as part of your procurement process require an invoice prior to payment), You agree that all payments made to UKRA must be done in advance of the Service being provided to you.</p>
				<h2>(5) Taxes</h2>
				<p>You are liable for paying all Taxes arising out of your purchase or Use of the Service. UKRA may charge You VAT (if applicable) at 20%, on any amount you pay for the Service, in accordance to the laws of England & Wales, including the Value Added Tax Act 1994 (as amended).</p>
				<h2>(6) Billing</h2>
				<p>6.1 Depending on how You pay for the Fees for Services for the account you hold, either clause 6.2 or 6.3 will apply. Clause 6.2 will apply to Your account if You pay the Fees for Services for that account using the UKRA website (and by extension its third-party payment processor). Clause 6.3 will apply to Your account if You pay for the Fees for Services by raising a purchase order and/or paying an invoice directly without using the UKRA website.</p>
				<p>6.2 This clause shall govern (to the exclusion of 6.3) if You pay the Fees for Services using the UKRA Members Portal (and by extension its third-party payment processor).</p>
				<p>If You are subscribing for a Paid Account, then You must pay during the signing up to, or during the upgrading of Your account.</p>
				<p>The Subscription Period runs from the date of payment until the end of December 31st in the same year. If you sign up during December, your Subscription will be valid until the end of December 31st in the following year.</p>
				<p>6.3 This clause shall govern (to the exclusion of 6.2) if You pay for the Fees for Services by raising a purchase order and/or paying an invoice directly without using the UKRA website.</p>
				<p>If You are subscribing for a Paid Account, then You must pay prior to the Service being provided to You.</p>
				<p>The Subscription Period runs from the date of payment until the end of December 31st in the same year. If you sign up during December, your Subscription will be valid until the end of December 31st in the following year.</p>
				<p>The payment terms are 30 days from the date of invoice that UKRA sends You.</p>
				<h2>(7) How To Cancel Your Subscription</h2>
				<p>To cancel Your subscription please login to Your account and click Close Account. We do not issue full or partial refunds for Subsciptions terminated before their due end date unless in exceptional circumstances. Please contact us about a specific case.</p>
				<h2>(8) Decline to Providing Services</h2>
				<p>UKRA reserves the right to decline or rescind any order You have made for Services. Non-acceptance of orders may result from UKRA's inability to obtain authorisation for your payment, the identification of a pricing or product description error, or your failure to comply with the Terms of Use.</p>
				<h2>(9) Refunds and Cancellation</h2>
				<p>9.1 If You are a Consumer and are located in the EU (or UK) You are entitled to cancel your agreement with UKRA and obtain a refund within 14 calendar days of ordering the Service.</p>
				<h2>(10) Conduct and Responsibilities</h2>
				<p>10.1 We expect our Users to exercise good manners and consideration when using UKRA Members Portal. You are solely responsible for all content You publish in connection with the Service. UKRA accepts no responsibility for any consequences incurred by the Use of the site, or for any of the content added by its Users.</p>
				<p>10.2 UKRA does not accept any responsibility for arrangements made between the Users or parties concerned Using the Service and cannot act as an intermediary. UKRA does not vet or assess the integrity of its Users, if you collaborate with Users of the Service you do so solely at 100% of your own risk, as such UKRA will not be liable for your collaboration with other Users or third parties in connection to the Service.</p>
				<p>10.3 You are expressly prohibited from and agree not to:</p>
				<p>10.3.1. Use the Service in a way which competes with UKRA;</p>
				<p>10.3.2. Scan or test the vulnerability of the Service or of UKRA's network;</p>
				<p>10.3.3. Deny access to other Users;</p>
				<p>10.3.4. Reverse engineer any part of the Service or of UKRA's Service IP Rights;</p>
				<p>10.3.5. Transmit or allow the transmission (by linking or otherwise) of any cyber security threat including but not limited to viruses, trojans, malware, worms, spyware, scareware, keylogger, backdoor, adware, wabbits, exploit software, dropper, dialer, botnet, illegitimate anti-virus, SQL injection, or any other malicious software;</p>
				<p>10.3.6. Carry out or facilitate a Distributed Denial of Service attack (DDoS);</p>
				<p>10.3.7. Commit an unlawful activity or infringe the Intellectual Property Rights of UKRA or any other third party;</p>
				<p>10.3.8. Resell, distribute, act as an agent or lease the Service without the permission of UKRA;</p>
				<p>10.3.9. Distribute, upload or publish anything that is considered SPAM; use your account in a manner that breaches our Anti-SPAM & Email Sending Policy (please see our Anti-SPAM & Email Sending Policy here: /anti-spam-policy); distribute, upload or publish anything that is considered defamatory, libellous, pornographic, obscene or abusive, promotes hatred, violence or illegal conduct or that otherwise violates any law or the rights of another in any jurisdiction;</p>
				<p>10.3.10. Use the Service in a manner which is excessive or against the interests of other Users;</p>
				<p>10.3.11. Use the Intellectual Property Rights of any third party without the proper permission of the rights holder; or,</p>
				<p>10.3.12. Allow multiple users to use the same account without UKRA's written agreement. Only one user is permitted for each account, unless otherwise agreed in writing by UKRA.</p>
				<p>10.4 If in the reasonable opinion of UKRA, You undertake any of the prohibitions mentioned above at clause 12.3 of the Terms of Use, UKRA may undertake any or all of the appropriate actions:</p>
				<p>10.4.1. Inform the police or appropriate local authorities;</p>
				<p>10.4.2. Delete, move, or edit content that violates;</p>
				<p>10.4.3. Terminate your account and these Terms with you, without offering any refunds whatsoever; and/or,</p>
				<p>10.4.4. Block your access from opening another account with UKRA in the future.</p>
				<h2>(11) Downtime</h2>
				<p>Please note that UKRA reserves the right to schedule downtime of the Service for maintenance, repairs and service improvements – this will be done during non-business hours.</p>
				<h2>(12) Location of Services as Supplied</h2>
				<p>UKRA provides you the Service from the United Kingdom (its principal place of business) – you agree to take up all requisite licences and agree to comply with any laws that apply to you regarding trading with UKRA from its principal place of business.</p>
				<h2>(13) Data Backup</h2>
				<p>UKRA is not responsible for data loss or corruption of any data You store with UKRA. You are responsible for maintaining, protecting and backing up of all of Your data. You are further highly recommended to make regular backups of Your data.</p>
				<h2>(14) Data Protection</h2>
				<p>14.1 UKRA will at all times comply with the Data Protection Laws. For more information on how we protect the User's data please see our Privacy Policy. While the Privacy Policy does not form a part of these Terms, they do indicate how UKRA operates, we may in some circumstances impute the Privacy Policy into these Terms if You ask us to and we expressly agree to this in writing.</p>
				<p>14.2 UKRA acts as data controller for any Personal Data which You store in Your account with UKRA about yourself.</p>
				<p>14.3 UKRA will only process Your Personal Data held in Your account in order to provide You with the Service.</p>
				<p>14.4 UKRA will at all times maintain appropriate physical and organisational measures to protect Personal Data belonging to You and ensure it is secure.</p>
				<p>14.5 UKRA will at all times limit access of Your Personal Data to its employees that have signed appropriate confidentiality agreements.</p>
				<p>14.6 UKRA will give You notice without undue delay of becoming aware of a Personal Data breach (as defined by Data Protection Laws) and will cooperate with You as necessary in order for You to make the appropriate notifications. UKRA will not disclose any Personal Data breach to a third party (which is not affiliated with You) without Your consent, unless it is demanded by a regulator, order of court or any other applicable legal requirements which require the disclosure of that Personal Data breach.</p>
				<p>14.7 UKRA will not use or disclose Your Personal Data unless it is required for providing the Service or if You agree with a particular use or disclosure.</p>
				<p>14.8 In the event that UKRA transfers any of Your Personal Data to a country outside the EEA, which is not recognised by the European Commission to have an adequate level of protection in accordance with Data Protection Laws, then UKRA will transfer subject only to an appropriate safeguard, including where necessary entering into the Standard Contractual Clauses.</p>
				<p>14.9 You give UKRA consent to engage its sub-processors to process Your Personal Data on our behalf in order for us to provide You with the Service. For a full list of all of the sub-processors we use please see the page: Sub-Processors.</p>
				<p>14.10 UKRA will inform You before allowing another sub-processor to process Your Personal Data, You will then have 10 days to object to the sub-processor otherwise You would be deemed to accept that sub-processor. If You object to the new sub-processor, You will have the right to terminate these Terms of Use with a notice period determined by UKRA without prejudice to any other rights You have under the law. When You object You will be able to terminate Your UKRA account and delete all Personal Data we process on Your behalf, this will not however entitle You to a refund (or allow Your refusal to pay) for any amount of the Fees for Services.</p>
				<p>14.11 UKRA shall enter into appropriate written agreements with all of its sub-processors on terms corresponding to the data protection provisions of these Terms of Use.</p>
				<p>14.12 UKRA will remain liable to You for the non-performance of the sub-processors' obligations, subject always to the limitations set out in clauses 19, 20 and 21 of the Terms of Use.</p>
				<p>14.13 UKRA by virtue of clause 16.1 agrees to make available to You as data controller, all information necessary to demonstrate compliance with the obligations laid down in Article 28 GDPR and allow for and contribute to audits, including inspections, conducted by Your organisation or another auditor mandated by your organisation.</p>
				<p>14.14 The types and categories of Personal Data are Your account information which you input as part of the sign-up process, any other data You store in Your account and data which your survey respondents put into the survey responses You collect from them using the Service and any information our cookies collect as part of the Service from You or Survey Respondents.</p>
				<p>14.15 The purpose of the processing is to enable UKRA to carry out its duties under these Terms (which form the subject matter of the processing) and the duration of the processing shall be until Your account is terminated or that data is deleted from Your account.</p>
				<p>14.16 The nature of processing involves the storage, transmission, transferring, retrieval, organisation and structuring. The nature may also be erasure where You have deleted personal data from Your account or where you ask us to do so.</p>
				<p>14.17 UKRA agrees that it will contact the account holder (or any other person designated by the account holder) if You are subscribed to a Paid Account, in order to inform You of Your contract period coming to an end, to engage with You to determine whether You may make use out of additional features of the Service by means of upgrading or otherwise, or to engage with You to determine how Your use of the Service is progressing in general. UKRA reserves the right not to perform this obligation if it does not wish to do so and this clause may only be exercised at UKRA's sole discretion.</p>
				<h2>(15) Confidentiality</h2>
				<p>15.1 You acknowledge that in and as a result of Using the Service, You may be making use of, acquiring Confidential Information of UKRA. Any Confidential Information is agreed to be the exclusive property of UKRA.</p>
				<p>15.2 You agree not to use any Confidential Information pertaining to UKRA and not to communicate any Confidential Information to any other party without the express and written permission of UKRA. This clause shall survive the termination or expiration of these Terms.</p>
				<p>15.3 You agree that, upon request of UKRA or upon termination or expiration of these Terms, as the case may be, You will turn over to UKRA or permanently erase all Confidential Information belonging to UKRA.</p>
				<h2>(16) Force Majeure</h2>
				<p>You expressly agree that UKRA shall not be liable for any consequences arising out of or in connection to any act, event, omission or accident beyond the reasonable control of UKRA (including without limitation, strikes, lock-outs or other industrial disputes, act of God, war, riot, civil commotion, failure of any telecommunications, failure of the internet, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant machinery or equipment, fire, flood or storm).</p>
				<h2>(17) Limitation of liability</h2>
				<p>TO THE MAXIMUM EXTENT PERMITTED BY LAW UKRA'S ENITRE LIABILITY TO YOU SHALL BE LIMITED TO A MAXIMUM OF 100% OF THE CHARGES PAID TO UKRA FOR ITS PERFORMANCE OF THE SERVICES.</p>
				<h2>(18) UKRA's Negligence</h2>
				<p>18.1 Subject to clause 36 of the Terms, in no event will UKRA's entire liability to You exceed 110% of the Fees for Services You have paid to UKRA or have yet to pay UKRA under contract (as the case may be), for any loss to You or any third party you are responsible for, arising out of or in connection to UKRA's breach of these Terms, this includes but is not limited to any and all damages for UKRA's (or its sub-contractors', agents' or employees') negligent acts or omissions which leads to a breach of these Terms, whether such breach was singular or in the aggregate, such damages include (but are not limited to) the following:</p>
				<p>18.1.1 Loss of anticipated savings;</p>
				<p>18.1.2 Loss of production;</p>
				<p>18.1.3 Loss of revenue;</p>
				<p>18.1.4 Business interruption;</p>
				<p>18.1.5 Increased cost of working;</p>
				<p>18.1.6 Damage to, or resulting from changes to, Your reputation;</p>
				<p>18.1.7 The costs and expenses incurred by You in finding and using replacement services to the Service;</p>
				<p>18.1.8 The costs and expenses in reconstituting lost or corrupted data;</p>
				<p>18.1.9 The costs and expenses of implementing and performing workarounds following a Service failure;</p>
				<p>18.1.10 Breach of Data Protection Laws;</p>
				<p>18.1.11 Losses incurred by You arising out of or in connection to any claim, demand, fine, penalty, investigation or proceeding from a third party (whether that third party is Your customer, a regulator, a sub-contractor, your employee, or a survey respondent) against You, caused by the act or omission of UKRA (or its sub-contractors, agents or employees'); or,</p>
				<p>18.1.12 Any other indirect, injured reputation, speculative, liquidated, quantum meruit, special, disappointment, incidental, consequential, punitive or exemplary damages.</p>
				<h2>(19) UKRA's Gross Negligence</h2>
				<p>19.1 Subject to clause 38 of the Terms, if UKRA is found liable to You due to Gross Negligence, then in no event will UKRA's entire liability to you exceed 130% of the Fees for Services you have paid to UKRA or have yet to pay UKRA under contract (as the case may be) for any loss to You arising out of or in connection to UKRA's breach of these Terms. This includes but is not limited to any and all damages for UKRA's (or its sub-contractors', agents' or employees') deliberate breaches of these Terms or defective performance, whether such breach or defective performance was singular or in the aggregate, such damages include (but are not limited to) the following:</p>
				<p>19.1.1 Loss of anticipated savings;</p>
				<p>19.1.2 Loss of production;</p>
				<p>19.1.3 Loss of revenue;</p>
				<p>19.1.4 Business interruption;</p>
				<p>19.1.5 Increased cost of working;</p>
				<p>19.1.6 Damage to, or resulting from changes to, Your reputation;</p>
				<p>19.1.7 The costs and expenses incurred by You in finding and using replacement services to the Service;</p>
				<p>19.1.8 The costs and expenses in reconstituting lost or corrupted data;</p>
				<p>19.1.9 The costs and expenses of implementing and performing workarounds following a Service failure;</p>
				<p>19.1.10 Breach of Data Protection Laws;</p>
				<p>19.1.11 Losses incurred by You arising out of or in connection to any claim, demand, fine, penalty, investigation or proceeding from a third party (whether that third party is Your customer, a regulator, a sub-contractor, your employee, or a survey respondent) against You, caused by the act or omission of UKRA (or its sub-contractors, agents or employees'); or,</p>
				<p>19.1.12 Any other indirect, injured reputation, speculative, liquidated, quantum meruit, special, disappointment, incidental, consequential, punitive or exemplary damages.</p>
				<h2>(20) Indemnity</h2>
				<p>20.1. You agree to indemnify and hold harmless UKRA and its subcontractors, agents and employees as a result of Your Use of the Service from and against any and all claims, costs and liabilities howsoever arising and of whatsoever nature, whether in contract or in tort, including but not limited to:</p>
				<p>20.1.1. Infringement of any Intellectual Property Rights of a third party;</p>
				<p>20.1.2. Claims by any third party or fines awarded by a regulator for breach of any Data Protection Laws, including (but not limited to) lack of lawful processing of Personal Data by You, Your sub-contractors or employees;</p>
				<p>20.1.3. Your breach of any law whether in the United Kingdom, where You are based and/or where it applies it You;</p>
				<p>20.1.4. Any acts of bribery committed by You, Your employees, agents or sub-contractors;</p>
				<p>20.1.5. Injury to or death of any person;</p>
				<p>20.1.6 Loss of or damage to any property;</p>
				<p>20.1.7. Court and reasonable attorney expenses and fees in connection with or resulting from UKRA enforcing this Indemnity clause; and,</p>
				<p>20.1.8. Your failure to obtain a licence or permission as described in clause 14 of these Terms of Use (“(14) Location of Services as Supplied”).</p>
				<p>20.2. This clause (“(22) Indemnity”) will not apply if any of the circumstances described were due to UKRA's (or any of its employees', agents' or subcontractors') negligence or Gross Negligence.</p>
				<h2>(21) Modern Slavery Act 2015</h2>
				<p>UKRA is fully aware of its obligations under the Modern Slavery Act 2015 (and The Modern Slavery Act 2015 (Transparency in Supply Chains) Regulations 2015, SI 2015/1833) and undertakes to be and remain fully in compliance of the legislation.</p>
				<h2>(22) Assignments & Delegations</h2>
				<p>22.1 You are prohibited from assigning Your rights and/or delegating Your obligations under these Terms to any other person or organisation. Should You want to assign a right or obligation, You can contact UKRA, UKRA will then render a decision based on its sole discretion as to whether You are granted consent or not for any assignments.</p>
				<p>22.2 UKRA has the right to assign or sub-contract any of its rights or obligations without requiring to notify You or obtain Your consent, unless as provided for by clause 16 (“(16) Data Protection”) of the Terms of Use.</p>
				<h2>(23) Your Compliance with laws</h2>
				<p>By Using the Service, You agree that it is Your responsibility to be in compliance of the laws that apply to You, wherever You are located.</p>
				<h2>(24) Law & Jurisdiction</h2>
				<p>24.1 You and UKRA hereby irrevocably agree that any dispute arising out of or in connection to these Terms shall be governed solely and exclusively by the laws of England & Wales.</p>
				<p>24.2 You and UKRA hereby irrevocably agree that the courts of England & Wales shall have the sole and exclusive jurisdiction to hear any dispute arising out of or in connection to these Terms.</p>
				<h2>(25) Alternative Dispute Resolution for Consumer Disputes</h2>
				<p>If you are a Consumer based in the EU, you may use the European Online Dispute Resolution platform to resolve disputes with us. The web address for this is: https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage</p>
				<p>For the purposes of this clause, our email address is <a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a>.</p>
				<h2>(26) Order of Precedence</h2>
				<p>These terms (along with any quote sent to You) contain the entire agreement between You and UKRA. Unless otherwise agreed in writing by UKRA, these Terms prevail over any other terms of business or purchase conditions put forward by You.</p>
				<h2>(27) Headings</h2>
				<p>The sections and headings in these Terms are for reference purposes only and shall not affect the meaning or interpretation of these Terms or individual clauses within these Terms.</p>
				<h2>(28) Severability</h2>
				<p>If any clause, sub-clause or any part thereof of these Terms is determined to be unenforceable by a competent court holding the proper jurisdiction, that clause, sub-clause or any part thereof will be severed and shall not affect the remainder of the agreement, including any other clauses, sub-clauses, or part(s) thereof.</p>
				<h2>(29) Publication</h2>
				<p>By Using the Service You agree for UKRA to have the right (but not the obligation to) publicise your name, trading name and/or trade mark on UKRA's website and/or marketing material as a customer of UKRA.</p>
				<h2>(30) Intellectual Property Rights</h2>
				<p>30.1 You grant UKRA the non-exclusive licence and right to use any Intellectual Property Rights you own as may be reasonably necessary to provide you the Service that you have requested.</p>
				<p>30.2 By Using the Service, UKRA is granting you the non-exclusive and temporary licence to use the Service IP Rights. This licence shall terminate when your account is either closed or deleted by any means through these Terms. You expressly prohibited from modifying any of the Service IP Rights without the express and written consent of UKRA.</p>
				<h2>(31) Intellectual Property Claims</h2>
				<p>If you believe that a customer of UKRA has used any part of the Service to infringe your Intellectual Property Rights, then please report it to <a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a> with the following details:</p>
				<p>Your name/organisation name:</p>
				<p>Email address:</p>
				<p>A description of the alleged infringement (please be as detailed as possible):</p>
				<p>Shortly thereafter, UKRA will look into the situation as quickly as possible and notify you of any decisions taken.</p>
				<h2>(32) User Content</h2>
				<p>You are responsible for all content you use, communicate or publish during Your Use of the Service. As such You must ensure that you have all the consents, licences, permissions and that You are authorised by law to display the content that you are using in relation to the Service.</p>
				<h2>(33) No waiver</h2>
				<p>33.1 No delay, neglect or forbearance on the part of either party to these Terms in enforcing against the other party any term or condition of this Agreement shall either be or be deemed to be a waiver or in any way prejudice any right of that party under this Agreement.</p>
				<p>33.2 No right, power or remedy in this Terms conferred upon or reserved for either party is exclusive of any other right, power or remedy available to that party.</p>
				<h2>(34) Third Party Rights</h2>
				<p>The Contracts (Rights of Third Parties) Act 1999 does not apply to this Agreement and no third party will have any right to enforce or rely on any provision of this Agreement.</p>
				<h2>(35) Report Content Abuse</h2>
				<p>If you believe that a User of UKRA's Services has used our Services inappropriately or in breach of these Terms (in particular clause 10 of these Terms “ (10) Conduct and Responsibilities”), then please contact <a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a> with the following details:</p>
				<p>Your name:</p>
				<p>Email address:</p>
				<p>A description of the alleged Service abuse whether it is phishing, spam, unlawful, inappropriate, contains harmful software, or for any other reason (please be as detailed as possible):</p>
				<p>Shortly thereafter, UKRA will look into the situation as quickly as possible and notify you of any decisions taken.</p>
				<h2>(36) Liability Provision</h2>
				<p>Nothing in this Agreement shall limit or exclude the liability of either party for:</p>
				<p>death or personal injury caused by negligence;</p>
				<p>any fraud or fraudulent misrepresentations; or,</p>
				<p>any liability that may not lawfully be limited or excluded.</p>
			</div>
			<div class="col-12 col-md-4">
				<div class="card shadow-light-lg">
					<div class="card-body">
						<h4>
							Have a question?
						</h4>
						<p class="font-size-sm text-gray-800 mb-5">
							Not sure exactly what we're looking for or just want clarification? We'd be happy to chat with you and clear things up for you. Anytime!
						</p>
						<h6 class="font-weight-bold text-uppercase text-gray-700 mb-2">
							Email us
						</h6>
						<p class="font-size-sm mb-0">
							<a href="mailto:enquiries@ukra.org.uk">enquiries@ukra.org.uk</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>