<script>
export const pageName = 'rockets-flight-edit';
export const pageRoute = '/rockets/{rocketId}/flights/{flightId}';
export const title = 'Edit Flight'
export const requireMembership = true;

import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import DateTime from '../../../functions/lib/dateTime.js';
import { getProjection } from '../../js/lib/client-read-model.js';
import { getCurrentUserId } from '../../js/auth.js';
import UserView from '../../../functions/domain/users/userView.js';
import RocketView from '../../../functions/domain/rockets/rocketView.js';

export default {
	data() {
		return { 
			date: null,
			dateMax: DateTime.now.format('yyyy-MM-dd'),
			time: null,
			name: null,
			location: null,
			latitude: null,
			longitude: null,
			motors: null,
			delay: null,
			mass: null,
			sm: null,
			notes: null,
			isDirty: false,
			ready: false
		}
	},

	methods: {
		beforeHide() {
			return this.isDirty ? 'Are you sure you want to abandon your changes?' : null
		},
		async show(opts) {
			functions.warmUp.rocketFlightUpdate();

			this.rocket = await getProjection([UserView, getCurrentUserId(), RocketView, opts.rocketId])
			this.flight = this.rocket.getFlight(opts.flightId);

			this.date = this.flight.flownOn.format('yyyy-MM-dd');
			this.time = this.flight.flownOn.format('HH:mm');

			this.name = this.flight.name;
			this.location = this.flight.location;
			this.latitude = this.flight.latitude;
			this.longitude = this.flight.longitude;
			this.motors = this.flight.motors;
			this.delay = this.flight.delay;
			this.mass = this.flight.mass;
			this.sm = this.flight.sm;
			this.notes = this.flight.notes;
			this.isDirty = false;
			
			this.ready = true;
		},
		geolocate() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.latitude = position.coords.latitude;
					this.longitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		changed() {
			this.isDirty = true;
		},
		async submit () {
			showLoading();

			let flownOn = `${this.date} ${this.time}`;
			flownOn = new DateTime(flownOn)

			let result = await functions.rocketFlightUpdate({ 
				rocketId: this.rocket.id,
				flightId: this.flight.id,
				flownOn,
				name: this.name,
				location: this.location,
				latitude: parseFloat(this.latitude || '0') || null,
				longitude: parseFloat(this.longitude || '0') || null,
				motors: this.motors,
				delay: parseFloat(this.delay || '0') || null,
				mass: parseInt(this.mass || '0', 10) || null,
				sm: parseInt(this.sm || '0', 10) || null,
				notes: this.notes
			});

			if(!result.success)
			{
				showToast({ message: 'Error editing flight', style: 'bg-danger'})
				hideLoading();
				return;
			}

			this.isDirty = false;

			navigate(`/rockets/${this.rocket.id}`)

			showToast({
				message: 'Flight updated',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Edit Flight</h1>
				<p class="text-muted text-center mb-5">Update a previous flight.</p>
					
				<form @submit.prevent="submit" @change="changed">
					<div class="form-group">
						<label class="form-label">
							Flight description
						</label>
						<input type="text" class="form-control" v-model="name" name="flight-desc" placeholder="e.g. First flight" autofocus>
						<span class="invalid-feedback mt-2 d-block"></span>
						<p class="form-text text-muted small mb-0">A unique description for this flight.</p>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Date</label>
								<input type="date" class="form-control pe-3" v-model="date" :max="dateMax">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Time</label>
								<input type="time" class="form-control pe-3" v-model="time">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<hr class="mt-2 mb-4" />
					<div class="form-group">
						<label class="form-label">Launch site name</label>
						<input type="text" class="form-control" v-model="location" placeholder="e.g. Cape Canaveral" autofocus>
						<span class="invalid-feedback"></span>
					</div>
					<div class="row">
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="latitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-4">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="longitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pb-4 ps-0">
							<button @click.prevent="geolocate" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					<hr class="mt-2 mb-4"/>
					<div class="row">
						<div class="col-9">
							<div class="form-group">
								<label class="form-label">
									Motor(s)
								</label>
								<input type="text" class="form-control" v-model="motors" placeholder="e.g. G80SK">
								<span class="feedback mt-2 d-block"></span>
							</div>
						</div>
						<div class="col-3">
							<div class="form-group">
								<label class="form-label">
									Delay
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="240" step="1" autocomplete="off" v-model="delay" class="form-control" placeholder="e.g. 7" />
									<div class="input-group-append">
										<div class="input-group-text">
											s
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Lift-off mass
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="mass" class="form-control" placeholder="e.g. 1100" />
									<div class="input-group-append">
										<div class="input-group-text">
											g
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Stability margin
								</label>
								<input type="number" class="form-control" v-model="sm" min="0" max="100" step="0.1" placeholder="e.g. 1.1">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label mb-1">Notes</label>
						<textarea class="form-control" v-model="notes" rows="4"></textarea>
					</div>
					<button class="btn w-100 btn-primary my-4">Save changes <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>
    </div>
</template>
