import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'

import Page from '../../js/page.js';
import * as pageManager from '@trullock/page-manager';

export default pageManager.registerPage('membership-buy-card-result', '/membership/buy-a-card/result', class extends Page {
	static requireAuth = true;
	static requireMembership = false
	static existingDomSelector = null;
	
	constructor()
	{
		super(null);
	}

	get title()
	{
		return 'Loading...';
	}

	async show(opts)
	{
		let session = await functions.stripeSessionResult({ session_id: opts.session_id })
		if (session.status == 'open') {
			
			showToast({
				message: 'Payment failed',
				style: 'bg-danger'
			})
			// This is how pagemanager lets you implement pseudo-pages, its a bit shit
			throw new pageManager.PageShowError('/membership/buy-a-card', '', {}, 'replace');
		} else if (session.status == 'complete') {
			showToast({
				message: 'Payment succeeded, your order is being processed.',
				style: 'bg-success'
			})
			// This is how pagemanager lets you implement pseudo-pages, its a bit shit
			throw new pageManager.PageShowError('/', '', {}, 'replace');
		}
	}

	hide()
	{
		return Promise.resolve();
	}
})